import { service } from 'aidbox-react/lib/services/service';

import { CrimesTotal } from 'shared/src/types/crimes';

export async function fetchCrimesTotalRange() {
    return service<CrimesTotal[] | undefined[]>({
        url: `/api/crimes/total-range`,
        method: 'GET',
    });
}

interface FetchCrimesParams {
    id?: number;
}

export async function fetchCrimesCityTotal(params?: FetchCrimesParams) {
    return service<CrimesTotal[] | undefined[]>({
        url: '/api/crimes/city-range/',
        method: 'GET',
        params,
    });
}
