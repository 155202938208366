import {
    BusinessesStatisticsParams,
    ConditionWeatherParams,
    Covid19StatisticsParams,
    CrimesStatisticsParams,
    WindWeatherParams,
} from 'shared/src/types/safe-cities';

import { ChoiceInputValue } from 'src/components/form/ChoiceInput';

export const conditionValues: ChoiceInputValue<ConditionWeatherParams | undefined>[] = [
    {
        text: 'Any',
        value: undefined,
    },
    {
        text: 'Sunny',
        value: 'sunny',
    },
    {
        text: 'Cloudy',
        value: 'cloudy',
    },
    {
        text: 'Rainy',
        value: 'rainy',
    },
];

export const windValues: ChoiceInputValue<WindWeatherParams | undefined>[] = [
    {
        text: 'Any',
        value: undefined,
    },
    {
        text: 'Light',
        value: 'light',
    },
    {
        text: 'Medium',
        value: 'medium',
    },
    {
        text: 'Strong',
        value: 'strong',
    },
];

export const businessesValues: ChoiceInputValue<BusinessesStatisticsParams | undefined>[] = [
    {
        text: 'Any',
        value: undefined,
    },
    {
        text: 'Few',
        value: 'few',
    },
    {
        text: 'Moderate',
        value: 'moderate',
    },
    {
        text: 'Many',
        value: 'many',
    },
];

export const crimesValues: ChoiceInputValue<CrimesStatisticsParams | undefined>[] = [
    {
        text: 'Any',
        value: undefined,
    },
    {
        text: 'Safe',
        value: 'safe',
    },
    {
        text: 'Mild',
        value: 'mild',
    },
    {
        text: 'Moderate',
        value: 'moderate',
    },
];

export const covid19Values: ChoiceInputValue<Covid19StatisticsParams | undefined>[] = [
    {
        text: 'Any',
        value: undefined,
    },
    {
        text: 'Safe',
        value: 'safe',
    },
    {
        text: 'Mild',
        value: 'mild',
    },
    {
        text: 'Moderate',
        value: 'moderate',
    },
];
