import React from 'react';

import { USCity } from 'shared/src/types/cities';

import { useWeather } from 'src/containers/CountyInfo/WeatherWidget';

import { CityDataBlock } from '../CityDataBlock';
import { RenderRemoteData } from '../RenderRemoteData';

interface Props {
    city: USCity;
    currentDataWidget: string | undefined;
    onDataWidget: (v: string | undefined) => void;
    onDetails: (v: boolean) => void;
}

const fromKtoF = (t: number) => Math.floor(((t - 273.15) * 9) / 5) + 32;

export function WeatherDataWidget(props: Props) {
    const { city, currentDataWidget, onDataWidget, onDetails } = props;
    const [weather] = useWeather(city);

    return (
        <RenderRemoteData remoteData={weather}>
            {(cityWeather) => (
                <CityDataBlock
                    title="Today"
                    id="wetherDataWidget"
                    imgIcon={`https://openweathermap.org/img/wn/${cityWeather.current.weather[0].icon}@2x.png`}
                    mainInfo={`${fromKtoF(cityWeather.daily[0].temp.day)}°`}
                    additionalInfo={`${fromKtoF(cityWeather.daily[0].temp.night)}°`}
                    onDetails={onDetails}
                    onDataWidget={onDataWidget}
                    currentDataWidget={currentDataWidget}
                />
            )}
        </RenderRemoteData>
    );
}
