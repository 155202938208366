import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

import { useService } from 'aidbox-react/lib/hooks/service';
import { isLoading, isSuccess } from 'aidbox-react/lib/libs/remoteData';

import { RatingWidget } from 'src/components/RatingWidget';
import { Spinner } from 'src/components/Spinner';
import { YelpLogo } from 'src/components/YelpLogo';
import { fetchCovid19History } from 'src/services/covid-19';
import { fetchBusiness, fetchBusinessReviews } from 'src/services/yelp';
import { sharedCounty } from 'src/shared';

import s from './BusinessModal.module.scss';

interface Props {
    id: string;
    onClose: () => void;
}

function getBusinessHours(hours: any[]) {
    const weekday = moment().weekday();
    const todayHours = _.find(hours[0].open, ({ day }) => day === weekday);
    const openNow = hours[0].is_open_now ? 'Open now' : 'Closed now';

    const yelpTimeFormat = 'HHmm';
    const displayFormat = 'h:mm A';

    if (todayHours) {
        const startTime = moment(todayHours.start, yelpTimeFormat).format(displayFormat);
        const endTime = moment(todayHours.end, yelpTimeFormat).format(displayFormat);

        return _.join([`${startTime} - ${endTime}`, openNow], ' • ');
    } else {
        return 'Closed today';
    }
}

const transactionsMap: { [key: string]: string } = {
    pickup: 'Curbside pickup',
    delivery: 'Delivery',
};

function useBusinessModal(props: Props) {
    const { id } = props;
    const [businessResponse] = useService(async () => await fetchBusiness(id), [id]);
    const [businessReviewsResponse] = useService(async () => await fetchBusinessReviews(id), [id]);

    const [county] = sharedCounty.useSharedState();
    const [covid19HistoryResponse] = useService(async () => fetchCovid19History(county!.fips), [county]);

    return {
        county,
        businessResponse,
        businessReviewsResponse,
        covid19HistoryResponse,
    };
}

export function BusinessModal(props: Props) {
    const { onClose } = props;
    const { businessResponse, businessReviewsResponse } = useBusinessModal(props);

    if (isLoading(businessResponse) || isLoading(businessReviewsResponse)) {
        return (
            <div className={s.container}>
                <Spinner />
            </div>
        );
    }

    if (isSuccess(businessResponse) && isSuccess(businessReviewsResponse)) {
        const business = businessResponse.data;
        const { name, photos, location, price, categories: businessCategories, rating, review_count } = business;
        const { reviews } = businessReviewsResponse.data;
        const address = _.join(
            _.compact([location.address1, location.address2, location.address3, location.city, location.state]),
            ', ',
        );
        const categories = _.join(_.compact(_.map(businessCategories, 'title')), ', ');

        return (
            <div className={s.container}>
                <div className={s.header}>
                    <div className={s.title}>{name}</div>
                    <button className={s.close} onClick={onClose} />
                </div>
                <div className={s.content}>
                    <div className={classNames(s.cardRecord, s.businessRating)}>
                        <RatingWidget rating={rating} className={s.rating} />
                        <YelpLogo />
                    </div>
                    <div className={classNames(s.cardRecord, s.businessReviews)}>Based on {review_count} Reviews</div>
                    <div className={s.cardRecord}>{_.join(_.compact([price, categories]), ' • ')}</div>
                    <div className={s.cardRecord} style={{ marginTop: 16 }}>
                        {address}
                    </div>
                    {business.hours ? <div className={s.cardRecord}>{getBusinessHours(business.hours)}</div> : null}
                    {business.transactions?.length ? (
                        <div className={s.cardRecord} style={{ marginTop: 16 }}>
                            {_.map(business.transactions, (transaction) => {
                                if (transaction && transactionsMap[transaction]) {
                                    return (
                                        <div className={s.transaction} key={`transaction-${transaction}`}>
                                            {transactionsMap[transaction]}
                                        </div>
                                    );
                                }

                                return null;
                            })}
                        </div>
                    ) : null}
                    {reviews.length ? (
                        <div className={s.reviews}>
                            {_.map(reviews, ({ text, id }) => (
                                <div className={s.review} key={`review-${id}`}>
                                    {`“${text}”`}
                                </div>
                            ))}
                        </div>
                    ) : null}
                    {photos.length ? (
                        <div className={s.images}>
                            {_.map(photos, (url) => (
                                <div className={s.image} key={`photo-${url}`}>
                                    <img src={url} alt="" />
                                </div>
                            ))}
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }

    return null;
}
