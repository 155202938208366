import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

import './styles.scss';

export interface ChoiceInputValue<T> {
    text: string;
    value: T;
}

export interface ChoiceInputProps<T> {
    name: string;
    value?: T[];
    values: ChoiceInputValue<T>[];
    onChange: (v: T[]) => void;
    multiple?: boolean;
}

export function ChoiceInput<T>(props: ChoiceInputProps<T>) {
    const { value, values, onChange, name, multiple } = props;
    const width = values.length ? `${(1 / values.length) * 100}%` : '100%';

    const onMultipleChange = (v: ChoiceInputValue<T>) => {
        if (_.isUndefined(value)) {
            onChange([v.value]);

            return;
        }

        if (_.indexOf(value, v.value) === -1) {
            onChange([...value, v.value]);
        } else {
            onChange(_.difference(value, [v.value]));
        }
    };

    const checkIfActive = (v: ChoiceInputValue<T>) => {
        let isActive = false;

        if (value && value.length) {
            isActive = _.indexOf(value, v.value) !== -1;
        } else {
            isActive = !v.value;
        }

        return isActive;
    };

    return (
        <div className="choice-input">
            {_.map(values, (v, index) => {
                const isActive = checkIfActive(v);
                const isPrevActive = index === 0 ? false : checkIfActive(values[index - 1]);
                const isNextActive = index + 1 === values.length ? false : checkIfActive(values[index + 1]);

                return (
                    <button
                        className={classNames('choice-input__button', {
                            _active: isActive,
                            _prevActive: isPrevActive,
                            _nextActive: isNextActive,
                        })}
                        style={{ width }}
                        key={`choice-input-${name}-${v.text}-${index}`}
                        onClick={() => {
                            if (_.isUndefined(v.value)) {
                                onChange([]);

                                return;
                            }

                            if (multiple) {
                                onMultipleChange(v);

                                return;
                            }

                            onChange([v.value]);
                        }}
                    >
                        {v.text}
                    </button>
                );
            })}
        </div>
    );
}
