import _ from 'lodash';
import React from 'react';

import { useService } from 'aidbox-react/lib/hooks/service';
import { isSuccess } from 'aidbox-react/lib/libs/remoteData';

import { CrimesTotal } from 'shared/src/types/crimes';
import { CrimesStatisticsParams } from 'shared/src/types/safe-cities';
import { isValueInRange } from 'shared/src/utils/misc';

import { useDataRanges } from 'src/containers/SearchModal';
import { fetchCrimesCityTotal } from 'src/services/crimes';

import { CityDataBlock } from '../CityDataBlock';

interface Props {
    cityId: number;
    currentDataWidget: string | undefined;
    onDataWidget: (v: string | undefined) => void;
    onDetails: (v: boolean) => void;
}

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function useCrimeData(props: Props) {
    const { cityId } = props;
    const { crimesRange } = useDataRanges();
    let crimesStatus = null;

    const [crimeResponse] = useService<CrimesTotal[] | undefined[]>(async () => {
        const res = fetchCrimesCityTotal({ id: cityId });
        return res;
    }, [cityId]);

    if (isSuccess(crimeResponse) && crimeResponse.data[0]) {
        const value = crimeResponse.data[0].total_normalized;
        if (!_.isNumber(value)) {
            return crimesStatus;
        }
        const index = _.findIndex(crimesRange, (r) => isValueInRange(value, r));
        const rangeValues: CrimesStatisticsParams[] = ['safe', 'mild', 'moderate'];
        if (rangeValues[index]) {
            crimesStatus = capitalizeFirstLetter(rangeValues[index]);
        }
    }
    return crimesStatus;
}

export function CrimesDataWidget(props: Props) {
    const { currentDataWidget, onDetails, onDataWidget } = props;

    const crimeData = useCrimeData(props);

    if (crimeData) {
        return (
            <CityDataBlock
                title="Crimes"
                id="crimesDataWidget"
                mainInfo={crimeData}
                currentDataWidget={currentDataWidget}
                onDetails={onDetails}
                onDataWidget={onDataWidget}
            />
        );
    } else {
        return (
            <CityDataBlock
                title="Crimes"
                id="crimesDataWidget"
                mainInfo="--"
                disabled
                currentDataWidget={currentDataWidget}
                onDetails={onDetails}
                onDataWidget={onDataWidget}
            />
        );
    }
}
