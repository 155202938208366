import React from 'react';

import { useService } from 'aidbox-react/lib/hooks/service';
import { failure, success, isSuccess } from 'aidbox-react/lib/libs/remoteData';

import config from 'shared/src/config';

import { WidgetBlock } from 'src/components/WidgetBlock';

import MobilityChart from './Chart';
import { MobilityData } from './types';


export function useMobility(county: string) {
    return useService<MobilityData[]>(
        async () =>
            fetch(config.baseURL + `/api/mobility/?county=${county}`)
                .then((response) => response.json())
                .then((data: MobilityData[]) => {
                    data.forEach((items) => {
                        items.statistics.sort((prevItem, nextItem) => {
                            return +new Date(prevItem.date) - +new Date(nextItem.date);
                        });
                    });

                    return success(data);
                })
                .catch((err) => failure(err.toString())),
        [county],
    );
}

interface Props {
    county: string;
    title?: string;
    width?: number;
}

export function MobilityWidget(props: Props) {
    const { county, width = 420 } = props;
    const [mobilityResponse] = useMobility(county);

    if (!isSuccess(mobilityResponse)) {
        return null;
    }

    if (!mobilityResponse.data || !mobilityResponse.data.length) {
        return null;
    }

    return (
        <WidgetBlock
            title="Mobility Trends"
            description="Change in the number of requests for routing from January 13, 2020"
        >
            <MobilityChart width={width} height={420} data={mobilityResponse.data} />
        </WidgetBlock>
    );
}
