import React from 'react';

import { CrimeData } from '../types';
import s from './CrimesStatisticPanel.module.scss';

interface Props {
    data: CrimeData[];
    total: number;
}

function calcStaticticData(props: Props) {
    const { data, total } = props;
    let robbery = 0;
    let theft = 0;
    let assault = 0;
    let shooting = 0;

    data.forEach((element) => {
        for (const key in element) {
            if (key === 'crimes') {
                for (const key in element.crimes) {
                    if (key === 'Robbery') {
                        robbery += element.crimes.Robbery;
                    } else if (key === 'Theft') {
                        theft += element.crimes.Theft;
                    } else if (key === 'Assault') {
                        assault += element.crimes.Assault;
                    } else if (key === 'Shooting') {
                        shooting += element.crimes.Shooting;
                    }
                }
            }
        }
    });

    const otherKindsCrimes = total - (robbery + theft + assault + shooting);

    const crimesStitistic = {
        total: total,
        Robbery: robbery,
        Theft: theft,
        Assault: assault,
        Shooting: shooting,
        otherKindsCrimes: otherKindsCrimes,
    };
    return crimesStitistic;
}
export function CrimesStatisticPanel(props: Props) {
    const crimesStitistic = calcStaticticData(props);

    return (
        <div className={s.crimesWrapper}>
            <ul className={s.crimesList}>
                <li className={s.crimesItem}>
                    <div className={s.crimesItemCount}>{crimesStitistic.Robbery}</div>
                    <div className={s.crimesItemTitle}>Robbery</div>
                </li>
                <li className={s.crimesItem}>
                    <div className={s.crimesItemCount}>{crimesStitistic.Theft}</div>
                    <div className={s.crimesItemTitle}>Theft</div>
                </li>
                <li className={s.crimesItem}>
                    <div className={s.crimesItemCount}>{crimesStitistic.Assault}</div>
                    <div className={s.crimesItemTitle}>Assault</div>
                </li>
                <li className={s.crimesItem}>
                    <div className={s.crimesItemCount}>{crimesStitistic.Shooting}</div>
                    <div className={s.crimesItemTitle}>Shooting</div>
                </li>
            </ul>
            <div className={s.crimesOtherTitle}>+ ${crimesStitistic.otherKindsCrimes} other indcidents</div>
        </div>
    );
}
