import { createSharedState } from 'aidbox-react/lib/hooks/shared-state';

import { USCounty } from 'shared/src/types/cities';
import {
    SafeCity,
    RatingKindValue,
    FilterCitiesByWeatherParams,
    FilterCitiesByStatisticsParams,
} from 'shared/src/types/safe-cities';

export const sharedCounty = createSharedState<USCounty | null>(null);
export const sharedCitiesList = createSharedState<SafeCity[]>([]);
export const sharedRatingKind = createSharedState<RatingKindValue>('appRating');
export const sharedFilters = createSharedState<FilterCitiesByWeatherParams & FilterCitiesByStatisticsParams>({});
export const sharedFiltersApply = createSharedState<boolean>(false);
