import classNames from 'classnames';
import React from 'react';

import { useService } from 'aidbox-react/lib/hooks/service';
import { success } from 'aidbox-react/lib/libs/remoteData';

import { MaterialIcon } from 'src/components/HistoryIcon';
import { RenderRemoteData } from 'src/components/RenderRemoteData';
import { UserTextBlock } from 'src/components/UserTextBlock';

import userHistoryImage from './history.svg';
import s from './UserHistory.module.scss';

interface UserHistory {
    type: string;
    details: {
        place_name?: string;
        city_name: string;
        date: string;
    };
}

export function UserHistory() {
    const [historyResponse] = useService<UserHistory[]>(() => Promise.resolve(success([] as UserHistory[])));
    return (
        <RenderRemoteData remoteData={historyResponse}>
            {(history) => (
                <>
                    {history.length ? (
                        <>
                            <p className={s.title}>Recently viewed</p>
                            <ul className={s.userHistoryList}>
                                {history.map((item) => (
                                    <HistoryItem {...item} />
                                ))}
                            </ul>
                        </>
                    ) : (
                        <UserTextBlock
                            img={userHistoryImage}
                            revieTitle="Here you will see history of your searches"
                            reviewText="We will save all the places you searched forso you can resume to them any time later"
                            btnText="Start searching"
                        ></UserTextBlock>
                    )}
                </>
            )}
        </RenderRemoteData>
    );
}

function HistoryItem(props: UserHistory) {
    const { type, details } = props;
    const { date, place_name, city_name } = details;
    return (
        <li className={s.HistoryItem}>
            {type === 'place' && <MaterialIcon iconText="location_city" className={classNames(s.icon)} />}
            {type === 'city' && <MaterialIcon iconText="room" className={classNames(s.icon)} />}
            <div className={s.HistoryItem__innerTextWrapper}>
                {type === 'place' && (
                    <>
                        <div className={s.HistoryItem__innerText}>{place_name}</div>
                        <div className={s.HistoryItem__innerText}>{city_name}</div>
                    </>
                )}
                {type === 'city' && <div className={s.HistoryItem__innerText}>{city_name}</div>}
            </div>
            <div className={s.HistoryItem__date}>{date}</div>
        </li>
    );
}
