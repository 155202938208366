import React from 'react';

import { useService } from 'aidbox-react/lib/hooks/service';
import { isSuccess } from 'aidbox-react/lib/libs/remoteData';

import { USCity, USCounty } from 'shared/src/types/cities';

import { Covid19Widget } from 'src/components/Covid19Widget';
import { Spinner } from 'src/components/Spinner';
import { WidgetBlock } from 'src/components/WidgetBlock';
import { fetchCovid19History } from 'src/services/covid-19';

import { CategoriesWidget } from './CategoriesWidget';
import s from './CountyInfo.module.scss';
import { CrimesWidget } from './CrimeWidget';
import { MobilityWidget } from './MobilityWidget';
import { WeatherWidget } from './WeatherWidget';

interface Props {
    county: USCounty;
    city: USCity;
    onClose: () => void;
    currentDataWidget: string | undefined;
}

function useCountyInfo(props: Props) {
    const { county } = props;
    const [covid19HistoryResponse] = useService(async () => fetchCovid19History(county.fips));

    return {
        county,
        covid19HistoryResponse,
    };
}

export function CountyInfo(props: Props) {
    const { onClose, city, currentDataWidget } = props;
    const { county, covid19HistoryResponse } = useCountyInfo(props);

    if (!county) {
        return null;
    }

    if (!isSuccess(covid19HistoryResponse)) {
        return (
            <div className={s.modal} data-testid="modal">
                <div className={s.container}>
                    <Spinner />
                </div>
            </div>
        );
    }

    const covid19History = covid19HistoryResponse.data;

    return (
        <div className={s.modal} data-testid="modal">
            <div className={s.container}>
                <div className={s.header}>
                    <div>
                        <div className={s.county}>
                            {covid19History.county.name}, {covid19History.county.state.abbr}
                        </div>
                    </div>
                    <button className={s.close} onClick={onClose} data-testid="close-modal" />
                </div>
                <div className={s.content}>
                    {currentDataWidget === 'wetherDataWidget' ? <WeatherWidget city={city} /> : null}

                    {currentDataWidget === 'covidDataWidget' ? (
                        <WidgetBlock title="COVID-19 Stats">
                            <Covid19Widget covid19History={covid19History} />
                        </WidgetBlock>
                    ) : null}

                    {currentDataWidget === 'mobilityDataWidget' ? <MobilityWidget county={county.name} /> : null}
                    {currentDataWidget === 'businessesDataWidget' ? <CategoriesWidget cityId={city.id} /> : null}
                    {currentDataWidget === 'crimesDataWidget' ? <CrimesWidget cityId={city.id} /> : null}
                </div>
            </div>
        </div>
    );
}
