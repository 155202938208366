import classNames from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { sharedCounty } from 'src/shared';

import { MaterialIcon } from '../HistoryIcon';
import s from './MenuItem.module.scss';

interface Props {
    icon: string;
    title: string;
    link: string;
}

export function MenuItem(props: Props) {
    const { icon, title, link } = props;
    const [, setCounty] = sharedCounty.useSharedState();

    return (
        <NavLink
            to={link}
            className={classNames(s.menuItem, { [s.didabled]: title === 'Departure' })}
            activeClassName={s._active}
            onClick={() => setCounty(null)}
        >
            <MaterialIcon iconText={icon} className={classNames(s.icon)} />
            {title}
        </NavLink>
    );
}
