import _ from 'lodash';

import { BusinessesStatisticsParams, CrimesStatisticsParams } from '../types/safe-cities';

export function isValueInRange(value: number, range: number[]) {
    const [min = 0, max] = range;

    if (value >= min) {
        if (_.isUndefined(max) || value < max) {
            return true;
        }
    }

    return false;
}

export function getMedian(values: number[]): number | undefined {
    if (values.length === 0) {
        return undefined;
    }

    const sortedValues = _.sortBy(values);

    const half = _.ceil(sortedValues.length / 2);

    if (half % 2) {
        return (sortedValues[half] + sortedValues[half - 1]) / 2;
    } else {
        return sortedValues[half];
    }
}

export function getRange(start?: number, end?: number) {
    if (!_.isNumber(start) || !_.isNumber(end)) {
        return [];
    }

    const diff = end - start;
    const percentage50 = start + diff * 0.5;
    const percentage80 = start + diff * 0.8;

    return [[0, percentage50], [percentage50, percentage80], [percentage80]];
}

export interface Range {
    id: string;
    range: number[];
}

export function getBusinessesRangesForSearch(min: number, max: number): Range[] {
    const ranges = getRange(min, max);
    const rangeValues: BusinessesStatisticsParams[] = ['few', 'moderate', 'many'];

    return _.map(ranges, (range, index) => ({
        id: rangeValues[index],
        range,
    }));
}

export function getCrimesRangesForSearch(min: number, max: number): Range[] {
    const ranges = getRange(min, max);
    const rangeValues: CrimesStatisticsParams[] = ['safe', 'mild', 'moderate'];

    return _.map(ranges, (range, index) => ({
        id: rangeValues[index],
        range,
    }));
}
