export type MainCategoryAlias = 'food' | 'shopping' | 'nightlife' | 'active' | 'beautysvc' | 'arts';

export interface MainCategory extends BusinessCategory {
    alias: MainCategoryAlias;
}

export const mainCategories: MainCategory[] = [
    {
        alias: 'food',
        title: 'Restaurants',
    },
    {
        alias: 'shopping',
        title: 'Shopping',
    },
    {
        alias: 'nightlife',
        title: 'Nightlife',
    },
    {
        alias: 'active',
        title: 'Parks',
    },
    {
        alias: 'beautysvc',
        title: 'Spa',
    },
    {
        alias: 'arts',
        title: 'Cultural life',
    },
];

export interface BusinessCategory {
    alias: string;
    title: string;
}

export type BusinessTransaction = 'pickup' | 'delivery' | 'restaurant_reservation';
export type BusinessPrice = '$' | '$$' | '$$$' | '$$$$';

export interface Business {
    rating: number;
    price: BusinessPrice;
    phone: string;
    id: string;
    alias: string;
    is_closed: boolean;
    categories: BusinessCategory[];
    review_count: number;
    name: string;
    url: string;
    coordinates: {
        latitude: number;
        longitude: number;
    };
    image_url: string;
    location: {
        city: string;
        country: string;
        address2: string;
        address3: string;
        state: string;
        address1: string;
        zip_code: string;
    };
    distance: number;
    transactions: BusinessTransaction[];
}

export interface BusinessDetails extends Business {
    photos: string[];
    hours: BusinessDetailsHours[];
}

export interface BusinessDetailsHours {
    open: Array<{
        is_overnight: boolean;
        start: string;
        end: string;
        day: number;
    }>;
    hours_type: 'REGULAR';
    is_open_now: boolean;
}

export interface BusinessBundle {
    total: number;
    businesses: Business[];
    region: {
        center: {
            latitude: number;
            longitude: number;
        };
    };
}

export interface BusinessReview {
    id: string;
    rating: number;
    user: {
        id: string;
        profile_url: string;
        image_url: string;
        name: string;
    };
    text: string;
    time_created: string;
    url: string;
}

export interface BusinessReviewBundle {
    reviews: BusinessReview[];
    total: number;
    possible_languages: string[];
}

export type BusinessAttributesSearchParams =
    | 'hot_and_new'
    | 'request_a_quote'
    | 'reservation'
    | 'waitlist_reservation'
    | 'deals'
    | 'gender_neutral_restrooms'
    | 'open_to_all'
    | 'wheelchair_accessible';

export interface BusinessDefaultSearchParams {
    term?: string;
    radius?: number;
    categories?: MainCategoryAlias[];
    locale?: string;
    limit?: number;
    offset?: number;
    sort_by?: 'best_match' | 'rating' | 'review_count' | 'distance';
    price?: number[];
    open_now?: boolean;
    open_at?: number;
    attributes?: BusinessAttributesSearchParams[];
}

export interface BusinessLocationSearchParams extends BusinessDefaultSearchParams {
    location: string;
}
export interface BusinessLatLonSearchParams extends BusinessDefaultSearchParams {
    latitude: string;
    longitude: string;
}

export type BusinessSearchParams = BusinessLocationSearchParams | BusinessLatLonSearchParams;
