import React from 'react';

import './styles.scss';
import { Business, Covid, Crime } from 'shared/src/utils/review';

export interface CityRatingProps {
    score: string;
    reviewsCount: number;
    business: Business;
    covid: Covid;
    crime: Crime;
}

export function CityRating({ reviewsCount, business, covid, crime, score }: CityRatingProps) {
    let color = '#FF6666';

    if (parseFloat(score) > 2.6) {
        color = '#ffd466';
    }

    if (parseFloat(score) > 3.6) {
        color = '#43a86b';
    }

    return (
        <div className="app-cityrating">
            <div className="app-cityrating__score">
                <div className="app-cityrating__score-text">{score}</div>
                <svg className="app-cityrating__wrapper">
                    <circle
                        className="app-cityrating__circle"
                        cx="40"
                        cy="40"
                        stroke={color}
                        r="36"
                        fill="transparent"
                        strokeWidth="8"
                        style={{ strokeDashoffset: `${226 - (Number(score) / 5) * 226}` }}
                    />
                </svg>
            </div>
            <div className="app-cityrating__info">
                <div className="app-cityrating__count-title">
                    {' '}
                    Overall rating by {reviewsCount} reviews{' '}
                    <span className="material-icons app-cityrating__count-title__icon">help_outline</span>
                </div>
                <div className="app-cityrating__option">
                    <div className="app-cityrating__option-label">Opened businesses:</div>
                    <div className="app-cityrating__option-value">{business}</div>
                </div>
                <div className="app-cityrating__option">
                    <div className="app-cityrating__option-label">COVID-19 cases:</div>
                    <div className="app-cityrating__option-value">{covid}</div>
                </div>
                <div className="app-cityrating__option">
                    <div className="app-cityrating__option-label">Crimes:</div>
                    <div className="app-cityrating__option-value">{crime}</div>
                </div>
            </div>
        </div>
    );
}
