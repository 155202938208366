import { line, curveCardinal } from 'd3-shape';
import _ from 'lodash';
import React from 'react';

import { MobilityData, MobilityStatistic } from '../types';
import s from './Chat.module.scss';

interface Props {
    width: number;
    height: number;
    strokeWidth?: number;
    data: MobilityData[];
}

type StrokeColor = keyof typeof strokeColorMap;

const strokeColorMap = {
    driving: '#fe2d55',
    walking: '#ff9402',
    transit: '#ae51de',
};

const defaultStrokeColor = '#aeadb2';

const legendTitles = {
    driving: 'Driving',
    walking: 'Walking',
    transit: 'Transit',
};

const stepPercents = [10, 20, 40, 50, 100];

const svgPaddingHorizontal = 10;
const svgPaddingVertical = 10;
const svgTextPercentOffset = 50;
const svgPathStrokeWidth = 2;
const svgPathLeftOffset = 45;

const MAX_STEPS = 10;

function useChart(props: Props) {
    const { width, height, data } = props;

    const items = data.filter((items) => items.statistics && items.statistics.length);

    const maxValues = items.map(({ statistics }) =>
        statistics.reduce((accumulator: number, item) => {
            const value = Number(item.value) - 100;
            return accumulator > value ? accumulator : value;
        }, 0),
    );

    const minValues = items.map(({ statistics }) =>
        statistics.reduce((accumulator: number, item) => {
            const value = Number(item.value) - 100;
            return accumulator < value ? accumulator : value;
        }, 0),
    );

    const minValue = Math.max(...minValues.map(Math.abs));
    const maxValue = Math.max(...maxValues);

    const index = stepPercents.findIndex((stepPercent) => {
        const minNormalized = Math.ceil(minValue / stepPercent) * stepPercent;
        const maxNormalized = Math.ceil(maxValue / stepPercent) * stepPercent;
        const scalePercent = minNormalized + maxNormalized;
        const steps = Math.ceil(scalePercent / stepPercent);

        return steps <= MAX_STEPS;
    });

    const normalizedIndex = index !== -1 ? index : stepPercents[stepPercents.length - 1];

    const stepPercent = stepPercents[normalizedIndex];
    const minPercent = Math.ceil(minValue / stepPercent) * stepPercent;
    const maxPercent = Math.ceil(maxValue / stepPercent) * stepPercent;
    const scalePercent = minPercent + maxPercent;

    return {
        paths: items.map((items) => {
            const len = items.statistics.length;
            const lastItem = items.statistics[len - 1];

            const d = line<MobilityStatistic>()
                .x((_, index) => ((width - svgTextPercentOffset) / len) * index + svgTextPercentOffset)
                .y((d) => {
                    const yCenter = height / 2;
                    const percentValue = Number(d.value) / 100 - 1;
                    const percentHeight = (height / scalePercent) * 100;

                    return yCenter - percentValue * percentHeight;
                })
                .curve(curveCardinal)(items.statistics)!;

            return {
                svg: {
                    d,
                    color: strokeColorMap[items.type as StrokeColor] || defaultStrokeColor,
                },
                item: {
                    type: items.type,
                    title: legendTitles[items.type as keyof typeof legendTitles],
                    value: Number(lastItem.value) - 100,
                },
            };
        }),
        percent: {
            step: stepPercent,
            scale: scalePercent,
            min: minPercent,
            max: maxPercent,
        },
        svg: {
            width: width,
            height: height,
        },
    };
}

export default function MobilityChart(props: Props) {
    const { svg, paths, percent } = useChart(props);
    const { width, height } = props;

    const length = Math.ceil(percent.scale / percent.step);
    const yCenter = height / 2;

    const lineStepHeight = height / length;
    const svgVerticalOffset = ((height / 2) * (percent.max - percent.min)) / percent.scale;

    return (
        <div>
            <svg
                width={svg.width + svgPaddingHorizontal * 2}
                height={svg.height + svgPaddingVertical * 2}
                className={s.chart}
            >
                <g transform={`translate(0, ${svgVerticalOffset + svgPaddingVertical})`}>
                    <line x1="0" x2={width} y1={yCenter} y2={yCenter} stroke="#999" />

                    {_.range(0, length).map((_, i) => {
                        const yLineAbove = yCenter - lineStepHeight * (i + 1);
                        const yLineBelow = yCenter + lineStepHeight * (i + 1);
                        const percentCurrent = percent.step * (i + 1);

                        return (
                            <React.Fragment key={i}>
                                {percentCurrent < percent.min && (
                                    <>
                                        <line
                                            x1={svgPathLeftOffset}
                                            x2={width}
                                            y1={yLineBelow}
                                            y2={yLineBelow}
                                            stroke="#ccc"
                                        />
                                        <text x="0" y={yLineBelow + 4} className={s.chartPercentText}>
                                            -{percentCurrent}%
                                        </text>
                                    </>
                                )}
                                {percentCurrent < percent.max && (
                                    <>
                                        <line
                                            x1={svgPathLeftOffset}
                                            x2={width}
                                            y1={yLineAbove}
                                            y2={yLineAbove}
                                            stroke="#ccc"
                                        />
                                        <text x="0" y={yLineAbove + 4} className={s.percentText}>
                                            +{percentCurrent}%
                                        </text>
                                    </>
                                )}
                            </React.Fragment>
                        );
                    })}

                    {paths.map(({ svg }, key) => {
                        return (
                            <path
                                key={key}
                                d={svg.d}
                                stroke={svg.color}
                                strokeWidth={svgPathStrokeWidth}
                                fill="none"
                                strokeLinecap="round"
                            />
                        );
                    })}
                </g>
            </svg>
            <div className={s.legend}>
                {paths.map(({ svg, item }, key) => (
                    <div key={key} className={s.legendRow}>
                        <div className={s.legendRowDot} style={{ backgroundColor: svg.color }}></div>
                        <div className={s.legendRowTitle} style={{ color: svg.color }}>
                            {item.title} {Math.round(item.value)}&nbsp;%
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
