import classNames from 'classnames';
import React, { ReactNode } from 'react';

import './styles.scss';

interface Props {
    title: string;
    description?: string;
    children?: ReactNode;
    className?: string;
}

export function WidgetBlock(props: Props) {
    const { title, description, children, className } = props;

    return (
        <div className={classNames('widget-block', className)}>
            <div className="widget-block__title">{title}</div>
            {description ? <div className="widget-block__description">{description}</div> : null}
            <div className="widget-block__content">{children}</div>
        </div>
    );
}
