import React from 'react';

import { useService } from 'aidbox-react/lib/hooks/service';
import { service } from 'aidbox-react/lib/services/service';

import { FullReview } from 'shared/src/utils/review';

import { RenderRemoteData } from 'src/components/RenderRemoteData';
import { ReviewCard } from 'src/components/ReviewCard';
import { UserTextBlock } from 'src/components/UserTextBlock';

import firstReviewImage from '../../ReviewsModal/first-review.svg';
import s from './UserReviews.module.scss';

export function UserReviews() {
    const [reviewsRemoteData] = useService<Array<FullReview>>(() => {
        return service({
            url: '/api/userinfo/reviews',
        });
    });

    return (
        <RenderRemoteData remoteData={reviewsRemoteData}>
            {(fullCityReview) => (
                <>
                    <>
                        {!fullCityReview.length && (
                            <UserTextBlock
                                img={firstReviewImage}
                                revieTitle="Here you will see your reviews"
                                reviewText="You can share your travel experience with other users by leaving reviews on visited cities"
                                btnText="See Top 10 Low risk cities"
                            ></UserTextBlock>
                        )}
                    </>
                    {fullCityReview.length > 0 && (
                        <div className={s.userReviewsList}>
                            <p className={s.title}>Your posted reviews</p>
                            {fullCityReview.map((review, index) => (
                                <ReviewCard review={review} mode="forProfile" key={`user-review-${index}`} />
                            ))}
                        </div>
                    )}
                </>
            )}
        </RenderRemoteData>
    );
}
