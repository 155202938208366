import classNames from 'classnames';
import _ from 'lodash';
import React, { useCallback } from 'react';

import { BusinessDefaultSearchParams, mainCategories, MainCategoryAlias } from 'shared/src/types/yelp';

import { categoryIcons } from 'src/components/BusinessCategory';
import { ChoiceInput, ChoiceInputValue } from 'src/components/form/ChoiceInput';
import { SearchInput } from 'src/components/SearchInput';

import s from './BusinessFilters.module.scss';

const filterPrices: ChoiceInputValue<number | undefined>[] = [
    {
        text: 'Any',
        value: undefined,
    },
    {
        text: '$',
        value: 1,
    },
    {
        text: '$$',
        value: 2,
    },
    {
        text: '$$$',
        value: 3,
    },
    {
        text: '$$$$',
        value: 4,
    },
];

interface FilterCategoriesProps {
    className?: string;
    value?: MainCategoryAlias[];
    onChange: (categories: MainCategoryAlias[]) => void;
}

function useBusinessFilter(props: FilterCategoriesProps) {
    const { value = [], onChange } = props;

    const toggleCategory = useCallback(
        (alias: MainCategoryAlias) => {
            const isActive = _.includes(value, alias);

            if (isActive) {
                if (value.length === 1) {
                    onChange(_.map(mainCategories, (c) => c.alias));

                    return;
                }

                if (value.length === mainCategories.length) {
                    onChange([alias]);

                    return;
                }

                onChange(_.filter(value, (v) => v !== alias));
            } else {
                onChange([...value, alias]);
            }
        },
        [value, onChange],
    );

    return {
        value,
        toggleCategory,
    };
}

export function FilterCategories(props: FilterCategoriesProps) {
    const { className } = props;
    const { value, toggleCategory } = useBusinessFilter(props);

    return (
        <div className={classNames(s.categories, className)}>
            {_.map(mainCategories, (c) => {
                const { alias, title } = c;
                const icon = categoryIcons[c.alias];
                const isActive = !value.length || _.includes(value, c.alias);

                return (
                    <button
                        className={classNames(s.category, {
                            [s._active]: isActive,
                        })}
                        key={`search-category-${alias}`}
                        onClick={() => toggleCategory(c.alias)}
                    >
                        <div className={s.categoryIcon}>
                            <img src={icon} alt="" />
                        </div>
                        <div className={s.categoryName}>{title}</div>
                    </button>
                );
            })}
        </div>
    );
}

interface Props {
    filter: BusinessDefaultSearchParams;
    setFilter: (params: BusinessDefaultSearchParams) => void;
}

export function BusinessListFilter(props: Props) {
    const { filter, setFilter } = props;

    return (
        <>
            <div className={s.filter}>
                <FilterCategories
                    value={filter.categories}
                    onChange={(categories) =>
                        setFilter({
                            ...filter,
                            categories,
                        })
                    }
                />
            </div>
            <div className={s.filter}>
                <SearchInput
                    placeholder="Find location"
                    onChange={(term: string) =>
                        setFilter({
                            ...filter,
                            term,
                        })
                    }
                />
            </div>
            <div className={s.filter}>
                <ChoiceInput<number | undefined>
                    name="price"
                    value={filter.price}
                    values={filterPrices}
                    onChange={(price) =>
                        setFilter({
                            ...filter,
                            price: price as number[],
                        })
                    }
                    multiple
                />
            </div>
        </>
    );
}
