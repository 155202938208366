import classNames from 'classnames';
import _ from 'lodash';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import {
    BusinessesStatisticsParams,
    ConditionWeatherParams,
    Covid19StatisticsParams,
    CrimesStatisticsParams,
    FilterCitiesByStatisticsParams,
    FilterCitiesByWeatherParams,
    WindWeatherParams,
} from 'shared/src/types/safe-cities';

import { Button } from 'src/components/Button';
import { ChoiceInput } from 'src/components/form/ChoiceInput';
import { Slider } from 'src/components/form/Slider';
import { sharedFilters } from 'src/shared';

import s from './CitiesFilters.module.scss';
import { businessesValues, conditionValues, covid19Values, crimesValues, windValues } from './values';

interface Props {
    isSearchResultsLoading: boolean;
    opened: boolean;
    onFiltersToggle: (v: boolean) => void;
    onSubmit: (params: FilterCitiesByWeatherParams & FilterCitiesByStatisticsParams) => void;
}

function useCitiesFilters() {
    const [filterUpdated, setFilterUpdated] = useState<boolean>(false);

    const [filter, setFilter] = sharedFilters.useSharedState();
    const [changedFilter, setChangedFilter] = useState<FilterCitiesByWeatherParams & FilterCitiesByStatisticsParams>(
        {},
    );
    const onValueChange = useCallback((v) => {
        setChangedFilter(v);
        setFilterUpdated(true);
    }, []);

    return { filter, setFilter, onValueChange, filterUpdated, setFilterUpdated, changedFilter, setChangedFilter };
}

export function CitiesFilters(props: Props) {
    const { isSearchResultsLoading, opened, onFiltersToggle, onSubmit } = props;
    const {
        filter,
        setFilter,
        onValueChange,
        filterUpdated,
        setFilterUpdated,
        changedFilter,
        setChangedFilter,
    } = useCitiesFilters();
    const filterApplied = !_.isEmpty(filter);

    useEffect(() => {
        setChangedFilter(filter);
    }, [filter]);

    return (
        <div className={classNames(s.container, { [s._loading]: isSearchResultsLoading })}>
            <div
                className={classNames(s.header, {
                    [s._opened]: opened,
                    [s._filterApplied]: filterApplied,
                })}
            >
                <button className={classNames(s.button, s.headerBackground)} onClick={() => onFiltersToggle(!opened)} />
                <button className={classNames(s.button, s.headerTitle)} onClick={() => onFiltersToggle(!opened)}>
                    <div className={classNames(s.tuneIcon)} />
                    Tune filters
                </button>
                <div className={s.headerActions}>
                    {filterApplied ? (
                        <button
                            onClick={() => {
                                onFiltersToggle(false);
                                setFilterUpdated(false);
                                setFilter({});
                                onSubmit({});
                            }}
                            className={classNames(s.button, s.headerClearButton)}
                        >
                            Clear
                        </button>
                    ) : null}
                    <button className={s.button} onClick={() => onFiltersToggle(!opened)}>
                        <div className={s.arrowIcon} />
                    </button>
                </div>
            </div>
            {opened ? (
                <div className={s.content}>
                    <Filter
                        title="Temperature, °F"
                        renderFilter={() => (
                            <Slider
                                value={changedFilter.temperature}
                                min={0}
                                max={120}
                                onChange={(v) => {
                                    onValueChange({
                                        ...changedFilter,
                                        temperature: v,
                                    });
                                }}
                            />
                        )}
                    />
                    <Filter
                        title="Humidity, %"
                        renderFilter={() => (
                            <Slider
                                value={changedFilter.humidity}
                                min={30}
                                max={90}
                                onChange={(v) => {
                                    onValueChange({
                                        ...changedFilter,
                                        humidity: v,
                                    });
                                }}
                            />
                        )}
                    />
                    <Filter
                        title="Condition"
                        renderFilter={() => (
                            <ChoiceInput<ConditionWeatherParams | undefined>
                                name="condition"
                                value={changedFilter.condition}
                                values={conditionValues}
                                onChange={(v) =>
                                    onValueChange({
                                        ...changedFilter,
                                        condition: v,
                                    })
                                }
                                multiple
                            />
                        )}
                    />
                    <Filter
                        title="Wind"
                        renderFilter={() => (
                            <ChoiceInput<WindWeatherParams | undefined>
                                name="wind"
                                value={changedFilter.wind}
                                values={windValues}
                                onChange={(v) =>
                                    onValueChange({
                                        ...changedFilter,
                                        wind: v,
                                    })
                                }
                                multiple
                            />
                        )}
                    />
                    <Filter
                        title="Businesses"
                        renderFilter={() => (
                            <ChoiceInput<BusinessesStatisticsParams | undefined>
                                name="businesses"
                                value={changedFilter.businesses}
                                values={businessesValues}
                                onChange={(v) =>
                                    onValueChange({
                                        ...changedFilter,
                                        businesses: v,
                                    })
                                }
                                multiple
                            />
                        )}
                    />
                    <Filter
                        title="COVID-19 cases"
                        renderFilter={() => (
                            <ChoiceInput<Covid19StatisticsParams | undefined>
                                name="covid-19"
                                value={changedFilter.covid_19}
                                values={covid19Values}
                                onChange={(v) =>
                                    onValueChange({
                                        ...changedFilter,
                                        covid_19: v,
                                    })
                                }
                                multiple
                            />
                        )}
                    />
                    <Filter
                        title="Crimes"
                        renderFilter={() => (
                            <ChoiceInput<CrimesStatisticsParams | undefined>
                                name="crimes"
                                value={changedFilter.crimes}
                                values={crimesValues}
                                onChange={(v) =>
                                    onValueChange({
                                        ...changedFilter,
                                        crimes: v,
                                    })
                                }
                                multiple
                            />
                        )}
                    />
                    <div className={s.footer}>
                        <Button
                            onClick={() => {
                                setFilterUpdated(false);
                                onSubmit(changedFilter);
                                setFilter(changedFilter);
                                onFiltersToggle(false);
                            }}
                            disabled={!filterUpdated}
                        >
                            Apply filters
                        </Button>
                    </div>
                </div>
            ) : null}
        </div>
    );
}

interface FilterProps {
    title: string;
    renderFilter: () => ReactNode;
}

export function Filter(props: FilterProps) {
    const { title, renderFilter } = props;

    return (
        <div className={s.filter}>
            <div className={s.filterTitle}>{title}</div>
            <div className={s.filterField}>{renderFilter()}</div>
        </div>
    );
}
