import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import gfm from 'remark-gfm';

import { Spinner } from 'src/components/Spinner';
import { useModal } from 'src/containers/SearchModal';

import s from './StaticTextModal.module.scss';

interface Props {
    title: string;
    file: string;
}

function useStaticTextModal(props: Props) {
    const [text, setText] = useState('');

    useEffect(() => {
        (async () => {
            await fetch(props.file)
                .then((response) => response.text())
                .then((t) => setText(t));
        })();
    });

    return { text };
}

export function StaticTextModal(props: Props) {
    const { title } = props;
    const { text } = useStaticTextModal(props);

    const { setOpened } = useModal();
    return (
        <div className={s.modal}>
            <div className={s.container}>
                <div className={s.header}>
                    <div className={s.title}>{title}</div>
                    <Link
                        to={'/'}
                        className={s.close}
                        onClick={() => {
                            setOpened(true);
                        }}
                    />
                </div>
                {text ? (
                    <div className={s.content}>
                        <ReactMarkdown
                            plugins={[gfm]}
                            className={s.markdown}
                            renderers={{
                                link: (props) => {
                                    const { href, children } = props;
                                    const isLocalLink = _.startsWith(href, '/');

                                    if (isLocalLink) {
                                        return <Link to={href}>{children}</Link>;
                                    }

                                    return (
                                        <a href={href} target="_blank" rel="noopener noreferrer">
                                            {children}
                                        </a>
                                    );
                                },
                            }}
                        >
                            {text}
                        </ReactMarkdown>
                    </div>
                ) : (
                    <Spinner />
                )}
            </div>
        </div>
    );
}
