import classNames from 'classnames';
import React from 'react';

import s from './UserAvatar.module.scss';

export interface Props {
    imgLink: string;
    mode: Mode;
}

type Mode = 'userModal' | 'serchModal';

export function UserAvatar(props: Props) {
    const { imgLink, mode } = props;

    return (
        <div className={classNames(s.userAvatar, { [s._static]: mode === 'serchModal' })}>
            <img className={s.userAvatar__img} src={imgLink} alt="user avatar" />
            {/* {mode === 'userModal' && <button className={s.editPhotoBtn}>photo_camera</button>} */}
        </div>
    );
}
