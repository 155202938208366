import { failure, isSuccess, success } from 'aidbox-react/lib/libs/remoteData';
import { service } from 'aidbox-react/lib/services/service';

import { Bundle } from 'shared/src/types';
import { USCity } from 'shared/src/types/cities';
import { SafeCity, SafeCitiesParams } from 'shared/src/types/safe-cities';

export async function fetchCity(id: string) {
    return service<USCity>({
        url: `api/cities/${id}`,
        method: 'GET',
    });
}

export async function fetchCityByCounty(county: string) {
    const citiesResponse = await fetchCities({ county });

    if (isSuccess(citiesResponse)) {
        if (citiesResponse.data[0]) {
            return success(citiesResponse.data[0]);
        }

        return failure({});
    }

    return citiesResponse;
}

interface FetchCitiesParams {
    county?: string;
}

export async function fetchCities(params?: FetchCitiesParams) {
    return service<USCity[]>({
        url: '/api/cities',
        method: 'GET',
        params,
    });
}

export async function fetchSafeCities(params?: SafeCitiesParams) {
    return service<Bundle<SafeCity>>({
        url: '/api/safe-cities',
        method: 'GET',
        params: {
            ...params,
            temperature: params?.temperature ? JSON.stringify(params?.temperature) : undefined,
            humidity: params?.humidity ? JSON.stringify(params?.humidity) : undefined,
            condition: params?.condition ? JSON.stringify(params?.condition) : undefined,
            wind: params?.wind ? JSON.stringify(params?.wind) : undefined,
            covid_19: params?.covid_19 ? JSON.stringify(params?.covid_19) : undefined,
            businesses: params?.businesses ? JSON.stringify(params?.businesses) : undefined,
            crimes: params?.crimes ? JSON.stringify(params?.crimes) : undefined,
        },
    });
}
