import _ from 'lodash';
import { EventArgs } from 'react-ga';

import {
    FilterCitiesByStatisticsParams,
    FilterCitiesByWeatherParams,
    FilterCitiesOptionName,
} from 'shared/src/types/safe-cities';

import {
    businessesValues,
    conditionValues,
    covid19Values,
    crimesValues,
    windValues,
} from 'src/containers/SearchModal/CitiesFilters/values';

export function getCitiesFilterAnalyticsParams(
    key: FilterCitiesOptionName,
    params: FilterCitiesByWeatherParams & FilterCitiesByStatisticsParams,
): Pick<EventArgs, 'action' | 'label'> | undefined {
    const value = params[key];

    if (!value || _.isEmpty(value)) {
        return undefined;
    }

    if (key === 'temperature') {
        return {
            action: 'temperature',
            label: `min: ${params['temperature']?.min} °F, max: ${params['temperature']?.max} °F`,
        };
    }

    if (key === 'humidity') {
        return {
            action: 'humidity',
            label: `min: ${params['humidity']?.min} %, max: ${params['humidity']?.max} %`,
        };
    }

    if (key === 'condition') {
        return {
            action: 'condition',
            label: _.join(
                _.map(params['condition'], (v) => _.find(conditionValues, { value: v })?.text),
                ', ',
            ),
        };
    }

    if (key === 'wind') {
        return {
            action: 'wind',
            label: _.join(
                _.map(params['wind'], (v) => _.find(windValues, { value: v })?.text),
                ', ',
            ),
        };
    }

    if (key === 'covid_19') {
        return {
            action: 'COVID-19 cases',
            label: _.join(
                _.map(params['covid_19'], (v) => _.find(covid19Values, { value: v })?.text),
                ', ',
            ),
        };
    }

    if (key === 'businesses') {
        return {
            action: 'businesses',
            label: _.join(
                _.map(params['businesses'], (v) => _.find(businessesValues, { value: v })?.text),
                ', ',
            ),
        };
    }

    if (key === 'crimes') {
        return {
            action: 'crimes',
            label: _.join(
                _.map(params['crimes'], (v) => _.find(crimesValues, { value: v })?.text),
                ', ',
            ),
        };
    }

    return undefined;
}
