import classNames from 'classnames';
import _ from 'lodash';
import mapboxgl, { LngLatLike } from 'mapbox-gl';
import React, { useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import 'mapbox-gl/dist/mapbox-gl.css';

import { isSuccess } from 'aidbox-react/lib/libs/remoteData';

import { USCity } from 'shared/src/types/cities';
import { Covid19 } from 'shared/src/types/covid-19';
import { isValueInRange } from 'shared/src/utils/misc';

import { fetchCityByCounty } from 'src/services/cities';
import { sharedCitiesList, sharedCounty } from 'src/shared';

import s from './Map.module.scss';
import { safetyCategories } from './safety-categories';

mapboxgl.accessToken = 'pk.eyJ1IjoieWJlZGEiLCJhIjoiY2tob3Fwem1yMDlwNTJ4bHR0MnY2MGxkOSJ9.FR4TXfz7ikZcBVZ2eY0AyQ';

interface Props {
    covid19: Covid19[];
}

export interface Covid19GroupedCases {
    [key: string]: Covid19;
}

function getCovid19TotalCasesByCounty(covid19: Covid19[]): Covid19GroupedCases {
    let result: Covid19GroupedCases = {};

    _.forEach(covid19, (countyCases) => (result[countyCases.county.fips] = countyCases));

    return result;
}

function getCountiesByCases(covid19TotalCases: Covid19[]) {
    let countiesByCases: { [key: string]: string[] } = _.fromPairs(_.map(safetyCategories, (c) => [c.id, []]));

    _.forEach(covid19TotalCases, ({ new_cases_normalized, county }) => {
        if (_.isNumber(new_cases_normalized)) {
            const category = _.find(safetyCategories, ({ range }) => isValueInRange(new_cases_normalized, range))!;

            countiesByCases[category.id].push(county.fips);
        }
    });

    return countiesByCases;
}

function getMapSourceCountyFips(fips: string) {
    if (_.startsWith(fips, '0')) {
        return fips;
    }

    return _.toNumber(fips);
}

function useMapCounty(map: mapboxgl.Map | undefined) {
    const [county] = sharedCounty.useSharedState();
    const [city, setCity] = useState<USCity | undefined>();

    useMapCity(map, city);
    useMapCitiesList(map);

    useEffect(() => {
        (async () => {
            if (county) {
                const response = await fetchCityByCounty(county.fips);

                if (isSuccess(response)) {
                    if (!response.data || _.isEmpty(response.data)) {
                        setCity(undefined);
                    } else {
                        setCity(response.data);
                    }
                }
            } else {
                setCity(undefined);
            }
        })();
    }, [county]);
}

function useMapCitiesList(map?: mapboxgl.Map) {
    const [county] = sharedCounty.useSharedState();
    const [safeCities] = sharedCitiesList.useSharedState();
    const [markers, setMarkers] = useState<mapboxgl.Marker[]>([]);

    const displayMarkers = useCallback(() => {
        if (!map) {
            return;
        }

        setMarkers(
            _.map(safeCities, (safeCity, index) => {
                const element = document.createElement('div');
                element.className = s.city;
                element.innerText = `${index + 1}`;
                const coords: LngLatLike = [safeCity.city.geometry.y, safeCity.city.geometry.x];

                const marker = new mapboxgl.Marker(element).setLngLat(coords).addTo(map);

                return marker;
            }),
        );
    }, [map, safeCities]);

    useEffect(() => {
        displayMarkers();
    }, [map]);

    useEffect(() => {
        if (county) {
            _.forEach(markers, (m) => m.remove());
        } else {
            displayMarkers();
        }
    }, [county]);

    useEffect(() => {
        _.forEach(markers, (m) => m.remove());
        displayMarkers();
    }, [safeCities]);
}

function useMapCity(map?: mapboxgl.Map, city?: USCity) {
    const [marker, setMarker] = useState<mapboxgl.Marker | undefined>();

    const element = document.createElement('div');
    element.className = s.marker;

    const flyToCity = useCallback(
        (city: USCity) => {
            if (!map) {
                return;
            }

            const fips = getMapSourceCountyFips(city.county.fips);

            if (marker) {
                marker.setLngLat([city.geometry.y, city.geometry.x]);
            } else {
                setMarker(new mapboxgl.Marker(element).setLngLat([city.geometry.y, city.geometry.x]).addTo(map));
            }

            map.flyTo({
                center: [city.geometry.y, city.geometry.x],
                zoom: 5,
                offset: [300, 0],
                essential: true,
            });

            map.on('moveend', function (e: any) {
                map.setFilter('counties-highlighted', ['in', 'FIPS', fips]);
            });
        },
        [map, marker, element],
    );

    useEffect(() => {
        if (city) {
            flyToCity(city);
        }

        if (!city && marker) {
            marker.remove();
            setMarker(undefined);
        }
    }, [city, flyToCity, marker]);
}

function useMap(props: Props) {
    const { covid19 } = props;
    const [, setCounty] = sharedCounty.useSharedState();
    const [covid19TotalCasesByCounty] = useState(getCovid19TotalCasesByCounty(covid19));

    const [mapInstance, setMapInstance] = useState<mapboxgl.Map | undefined>();
    const [mapLoaded, setMapLoaded] = useState<boolean>(false);
    const [mapInitialized, setMapInitialized] = useState<boolean>(false);

    const popup = new mapboxgl.Popup({
        closeButton: false,
    });

    useMapCounty(mapInstance);

    const loadMap = useCallback(() => {
        const map = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/mapbox/light-v10',
            zoom: 3,
            center: [-118.59179687498357, 40.66995747013945],
            maxZoom: 8,
            minZoom: 3,
        });

        // disable map rotation using right click + drag
        map.dragRotate.disable();
        // disable map rotation using touch rotation gesture
        map.touchZoomRotate.disableRotation();

        setMapInstance(map);

        map.on('load', () => {
            setMapLoaded(true);
        });
    }, []);

    useEffect(() => {
        if (mapLoaded && !mapInitialized) {
            setMapInitialized(true);
            const map = mapInstance!;

            map.addSource('counties', {
                type: 'vector',
                url: 'mapbox://mapbox.82pkq93d',
            });

            map.addLayer({
                id: 'counties',
                type: 'fill',
                source: 'counties',
                'source-layer': 'original',
                paint: {
                    'fill-outline-color': '#bbb',
                    'fill-color': '#ddd',
                },
            });

            const countiesByCases = getCountiesByCases(covid19);

            _.forEach(safetyCategories, (p) => {
                const countiesFips = _.map(countiesByCases[p.id] ?? [], (v) => getMapSourceCountyFips(v));

                const layer: mapboxgl.AnyLayer = {
                    id: `counties-group-${p.id}`,
                    type: 'fill',
                    source: 'counties',
                    'source-layer': 'original',
                    paint: {
                        'fill-outline-color': p.borderColor,
                        'fill-color': p.color,
                        'fill-opacity': 1,
                    },
                    filter: ['in', 'FIPS', ...countiesFips],
                };

                map.addLayer(layer);
            });

            map.addLayer({
                id: 'counties-highlighted',
                type: 'fill',
                source: 'counties',
                'source-layer': 'original',
                paint: {
                    'fill-outline-color': 'transparent',
                    'fill-color': 'transparent',
                },
                filter: ['in', 'FIPS'],
            });

            map.on('mousemove', function (e: any) {
                const features = map.queryRenderedFeatures(e.point, {
                    layers: ['counties'],
                });

                map.getCanvas().style.cursor = features.length ? 'pointer' : '';

                if (!features.length) {
                    popup.remove();
                    return;
                }

                const feature = features[0];

                popup.setLngLat(e.lngLat).setText(feature.properties!.COUNTY).addTo(map);
            });

            map.on('click', function (e: any) {
                const { point } = e;
                const features = map.queryRenderedFeatures(point, {
                    layers: ['counties'],
                });
                const feature = features[0];

                const fips = feature?.properties?.FIPS;
                const data = covid19TotalCasesByCounty[fips];

                if (fips && data) {
                    setCounty(data.county);
                    ReactGA.event({
                        category: 'Map',
                        action: 'County area clicked',
                        label: `${data.county.name} county, ${data.county.state.abbr}`,
                    });
                } else {
                    setCounty(null);
                }
            });
        }
    }, [mapLoaded, mapInitialized, mapInstance, covid19, covid19TotalCasesByCounty, setCounty]);

    useEffect(() => {
        loadMap();
    }, [loadMap]);

    return { map: mapInstance };
}

export function Map(props: Props) {
    const { map } = useMap(props);

    return (
        <>
            <div id="map" className={s.map} />
            <div className={s.mapControls}>
                <button
                    className={classNames(s.mapControl, s._zoomIn)}
                    onClick={() => {
                        if (map) {
                            map.zoomIn();
                        }
                    }}
                />
                <button
                    className={classNames(s.mapControl, s._zoomOut)}
                    onClick={() => {
                        if (map) {
                            map.zoomOut();
                        }
                    }}
                />
            </div>
        </>
    );
}
