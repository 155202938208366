import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

import { MaterialIcon } from '../HistoryIcon';

import './styles.scss';

interface Props {
    rating: number;
    className?: string;
}

export function RatingWidget(props: Props) {
    const { rating, className } = props;

    return (
        <div className={classNames(className, 'business-rating')}>
            <div className="business-rating__items">
                {_.map(_.range(0, 5), (index) => {
                    const isLastInRating = rating < index + 1 && rating > index;

                    let backgroundColor = '#F43939';
                    let width = '100%';

                    if (isLastInRating) {
                        width = `${(rating % 1) * 100}%`;
                    }

                    if (!isLastInRating && index + 1 > rating) {
                        width = '0%';
                    }

                    if (rating === 5) {
                        backgroundColor = '#F43939';
                    } else if (rating >= 4) {
                        backgroundColor = '#FD513D';
                    } else if (rating >= 3) {
                        backgroundColor = '#FD7E42';
                    } else if (rating >= 2) {
                        backgroundColor = '#FDA348';
                    } else if (rating >= 1) {
                        backgroundColor = '#FDCB4C';
                    }

                    return (
                        <div className="business-rating__item">
                            <MaterialIcon iconText="star" className="business-rating__star" />
                            <div
                                className={classNames('business-rating__colored-item', {
                                    _active: index < rating,
                                })}
                                style={{ width, backgroundColor }}
                                key={`rating-active-${index}`}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
