import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useState } from 'react';

import { USCounty } from 'shared/src/types/cities';
import { SafeSityBySystem } from 'shared/src/types/safe-cities';

import { SafeCityBySystemRow } from 'src/containers/SearchModal';
import { removeFromFavoriteCity } from 'src/services/favoriteCities';

import { MaterialIcon } from '../HistoryIcon';
import s from './FavoritesCityItem.module.scss';

interface FavoriteItem {
    safeCity: SafeSityBySystem;
    setCounty: (s: USCounty | null) => void;
    index: number;
    businessesRange: number[][] | undefined;
    crimesRange: number[][] | undefined;
}

export function FavoritesCityItem(Props: FavoriteItem) {
    const { safeCity, setCounty, index, businessesRange, crimesRange } = Props;
    const [remove, setRemove] = useState(false);

    useEffect(() => {
        return () => {
            if (remove) {
                removeFromFavoriteCity(safeCity.city.id);
            }
        };
    }, [remove]);

    return (
        <div className={classNames(s.favoritesItem, { [s._remove]: remove })}>
            <div className={classNames(s.rowWrapper)}>
                <button
                    className={classNames(s.removeButton)}
                    onClick={() => {
                        setRemove(!remove);
                    }}
                >
                    <MaterialIcon
                        iconText={remove ? 'favorite_border' : 'favorite'}
                        className={classNames(s.bookmark)}
                    />
                </button>
                <SafeCityBySystemRow
                    safeCity={safeCity}
                    setCounty={setCounty}
                    index={index}
                    businessesRange={businessesRange}
                    crimesRange={crimesRange}
                />
            </div>
            {remove ? <div className={s.removeToolTip}>The city has been deleted from your list</div> : null}
        </div>
    );
}
