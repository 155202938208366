import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

import { useService } from 'aidbox-react/lib/hooks/service';
import { service } from 'aidbox-react/lib/services/service';

import { FullCityReview } from 'src/containers/ReviewsModal';

import { RenderRemoteData } from '../RenderRemoteData';
import s from './ReviewsButton.module.scss';
import reviewsIcon from './reviewsIcon.svg';

interface Props {
    cityId: number;
    onClick: () => void;
}

export default function ReviewsButton(props: Props) {
    const { cityId, onClick } = props;

    const [fullCityReviewRemoteData] = useService<FullCityReview>(() => {
        return service({
            url: `/api/cities/${cityId}/reviews`,
        });
    }, [cityId]);

    return (
        <RenderRemoteData
            remoteData={fullCityReviewRemoteData}
            renderLoading={ReviewsDisables}
            renderNotAsked={ReviewsDisables}
            renderFailure={ReviewsDisables}
        >
            {(reviews) => (
                <div className={s.reviewsButton} onClick={onClick}>
                    <div className={s.usersRatingWrapper}>
                        {_.map(_.range(0, 5), (index) => (
                            <div
                                className={classNames(s.usersRatingDot, {
                                    [s.usersRatingDot_active]:
                                        index < Math.round(Number(reviews.cityData?.raiting) | 0),
                                })}
                                key={`rating-${index}`}
                            />
                        ))}
                    </div>
                    <div className={s.usersReviewsIcon}>
                        <img src={reviewsIcon} alt="" />
                    </div>
                    <div className={s.reviewsCount}>{reviews.reviews ? reviews.reviews.length : 0}</div>
                </div>
            )}
        </RenderRemoteData>
    );
}

function ReviewsDisables() {
    return (
        <div className={classNames(s.reviewsButton, s.reviewsButton_disabled)}>
            <div className={s.usersRatingWrapper}>
                {_.map(_.range(0, 5), (index) => (
                    <div
                        className={classNames(s.usersRatingDot, {
                            [s.usersRatingDot_active]: index < 0,
                        })}
                        key={`rating-${index}`}
                    />
                ))}
            </div>
            <div className={s.usersReviewsIcon}>
                <img src={reviewsIcon} alt="" />
            </div>
            <div className={s.reviewsCount}>{0}</div>
        </div>
    );
}
