import React, { useEffect } from 'react';
import './styles.scss';

import { USCity } from 'shared/src/types/cities';

interface Props {
    cityData: USCity;
}
function getDate() {
    const nowDate = new Date();

    function getUTC(nowDate: Date) {
        if (Math.sign(nowDate.getTimezoneOffset() / -60) > 0) {
            return `+${nowDate.getTimezoneOffset() / -60}`;
        } else if (Math.sign(nowDate.getTimezoneOffset() / -60) === 0) {
            return ` ${nowDate.getTimezoneOffset() / -60}`;
        } else {
            return `${nowDate.getTimezoneOffset() / -60}`;
        }
    }

    const date = `${nowDate.toLocaleTimeString('en-US', {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
    })}, ${nowDate.toLocaleDateString('en-US', {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
    })}, (GMT${getUTC(nowDate)})`;

    return date;
}

export function SkySkanerWidget(props: Props) {
    const { cityData } = props;
    const date = getDate();

    useEffect(() => {
        const script = document.createElement('script');

        script.src = 'https://widgets.skyscanner.net/widget-server/js/loader.js';
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className="skyscaner-widget">
            <div>{date}</div>
            <div
                data-skyscanner-widget="FlightSearchWidget"
                data-locale="en-US"
                data-market="US"
                data-currency="USD"
                data-colour="#F0F0F0"
                data-button-colour="#3D67FF"
                data-flights-cabin-classes="false"
                data-flights-max-adults-number="1"
                data-flights-max-children-number="0"
                data-widget-scale="0.8"
                data-button-text-size="1"
                data-powered-by-size="1"
                data-destination-name={`'${cityData.name}'`}
            ></div>
        </div>
    );
}
