import React from 'react';
import { Redirect } from 'react-router';

export function UserDeparture() {
    return (
        <>
            <h1>User Departure Component</h1>
            <Redirect to="/user/preferences" />
        </>
    );
}
