import classNames from 'classnames';
import React from 'react';
import { Link, Redirect, useHistory, useRouteMatch } from 'react-router-dom';

import { isSuccess, notAsked } from 'aidbox-react/lib/libs/remoteData';
import { resetInstanceToken } from 'aidbox-react/lib/services/instance';

import { authData } from 'src/services/signin';
import { sharedFilters, sharedFiltersApply } from 'src/shared';

import { UserAvatar } from '../../components/UserAvatar';
import { UserMenu } from '../../components/UserMenu';
import s from './UserModal.module.scss';

function LogOut(setUserInfo: Function) {
    localStorage.clear();
    setUserInfo(notAsked);
    resetInstanceToken();
}
export function UserModal() {
    const [userInfo, setUserInfo] = authData.useSharedState();
    const [, setFilters] = sharedFilters.useSharedState();
    const [, setFiltersSetup] = sharedFiltersApply.useSharedState();

    let { url } = useRouteMatch();
    let history = useHistory();

    if (url[url.length - 1] === '/') {
        url = url.substring(0, url.length - 1);
        history.replace(`${url}`);
    }

    return (
        <>
            {isSuccess(userInfo) ? (
                <div className={classNames(s.modal)}>
                    <div className={s.container}>
                        <div className={s.header}>
                            <UserAvatar imgLink={userInfo.data.picture} mode="userModal"></UserAvatar>
                            <span className={s.userName}>{userInfo.data.name}</span>
                            <Link
                                to="/"
                                className={s.logOut}
                                onClick={() => {
                                    setFilters({});
                                    setFiltersSetup(false);
                                    LogOut(setUserInfo);
                                }}
                            >
                                Log Out
                            </Link>
                        </div>
                        <UserMenu url={url} />
                    </div>
                </div>
            ) : (
                <Redirect to="/" />
            )}
        </>
    );
}
