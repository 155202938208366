import React from 'react';

import { useService } from 'aidbox-react/lib/hooks/service';

import { MaterialIcon } from 'src/components/HistoryIcon';
import { RenderRemoteData } from 'src/components/RenderRemoteData';
import { UserFilters } from 'src/components/UserFilters';
import { getUserPreferences, setUserPreferences } from 'src/services/userPreferences';

import s from './UserPreferences.module.scss';

export function UserPreferences() {
    const [userDataResponse] = useService(async () => await getUserPreferences(), []);

    return (
        <RenderRemoteData remoteData={userDataResponse}>
            {(data) => {
                let filters;
                if (data) {
                    filters = JSON.parse(data.user_filters);
                }
                return (
                    <div className={s.wrapper}>
                        <div className={s.sectionTitleWrapper}>
                            <p className={s.title}>Choose your search preferences</p>
                            <MaterialIcon
                                iconText=""
                                className={s.questionIcon}
                                title={`Tune the parameters accordingly. We'll apply your choice to suggest the relevant TravelMoov content. You can always change the preferences on the profile page.`}
                            />
                        </div>
                        <UserFilters
                            filters={filters}
                            onSubmit={setUserPreferences}
                            userProp="user_filters"
                        ></UserFilters>
                    </div>
                );
            }}
        </RenderRemoteData>
    );
}
