import React from 'react';

import { useService } from 'aidbox-react/lib/hooks/service';

import { fetchCityBusinessesTotal } from 'src/services/yelp';

import { CityDataBlock } from '../CityDataBlock';
import { RenderRemoteData } from '../RenderRemoteData';

interface Props {
    id: number;
    currentDataWidget: string | undefined;
    onDataWidget: (v: string | undefined) => void;
    onDetails: (v: boolean) => void;
}

export function BusinessesDataWidget(props: Props) {
    const { id, currentDataWidget, onDetails, onDataWidget } = props;
    const [totlalBusinessesResponse] = useService(async () => fetchCityBusinessesTotal(id), [id]);

    return (
        <RenderRemoteData remoteData={totlalBusinessesResponse}>
            {(businessesData) => (
                <CityDataBlock
                    title="Businesses"
                    id="businessesDataWidget"
                    textIcon="business"
                    mainInfo={`${businessesData.total}`}
                    currentDataWidget={currentDataWidget}
                    onDetails={onDetails}
                    onDataWidget={onDataWidget}
                />
            )}
        </RenderRemoteData>
    );
}
