import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { Route, Switch, Router } from 'react-router-dom';

import { useService } from 'aidbox-react/lib/hooks/service';
import { isSuccess, loading, RemoteData } from 'aidbox-react/lib/libs/remoteData';
import { service } from 'aidbox-react/lib/services/service';

import { Bundle } from 'shared/src/types';
import { USCity } from 'shared/src/types/cities';
import { Covid19 } from 'shared/src/types/covid-19';
import { defaultSearchSafeCitiesParams, SafeCity } from 'shared/src/types/safe-cities';
import { CountyRating } from 'shared/src/utils/review';

import { Spinner } from 'src/components/Spinner';
import { fetchSafeCities } from 'src/services/cities';
import { fetchCovid19 } from 'src/services/covid-19';
import { history } from 'src/services/history';
import { sharedCitiesList, sharedRatingKind } from 'src/shared';

import { Footer } from '../Footer';
import { Map } from '../Map';
import { ReviewsModal } from '../ReviewsModal';
import { SafeCitiesDetails } from '../SafeCitiesDetails';
import { SearchModal } from '../SearchModal';
import { UserModal } from '../UserModal';
import { Map as UserReviewMap } from '../UserReviewMap';
import { UserStatistic } from '../UserStatistic';

function useApp() {
    const [ratingKind] = sharedRatingKind.useSharedState();
    const [covid19Response] = useService<Covid19[]>(fetchCovid19);
    const [userCountyReviewsResponse, setUserCountyReviewsResponse] = useState<RemoteData<Array<CountyRating>>>(
        loading,
    );
    const [, { reload }] = useService<CountyRating[]>(async () => {
        const res = await service({ url: '/api/review' });
        if (isSuccess(res)) {
            setUserCountyReviewsResponse(res);
        }
        return res;
    });
    const [citiesResponse, setCitiesResponse] = useState<RemoteData<Bundle<SafeCity>>>(loading);
    useService<Bundle<SafeCity>>(async () => {
        const res = await fetchSafeCities({
            ...defaultSearchSafeCitiesParams,
            kind: ratingKind,
        });
        if (isSuccess(res)) {
            setCitiesResponse(res);
        }
        return res;
    }, [ratingKind]);

    useEffect(() => {
        ReactGA.pageview('/');
    }, []);

    useEffect(() => {
        if (isSuccess(citiesResponse)) {
            sharedCitiesList.setSharedState(citiesResponse.data.entry);
        }
    }, [citiesResponse]);

    return {
        covid19Response,
        citiesResponse,
        ratingKind,
        userCountyReviewsResponse,
        reloadReview: reload,
    };
}

interface NoneModal {
    type: 'None';
}
/*
 * const noneModal:NoneModal = {
 *     type: 'None'
 * }
 *  */
interface SearchModal_ {
    type: 'Search';
}

const searchModal: SearchModal_ = {
    type: 'Search',
};

interface ReviewModal {
    type: 'Review';
    city: USCity;
}
function reviewModal(city: USCity): ReviewModal {
    return {
        type: 'Review',
        city,
    };
}

type ActiveModal = NoneModal | SearchModal_ | ReviewModal;

export function App() {
    const { covid19Response, citiesResponse, ratingKind, userCountyReviewsResponse, reloadReview } = useApp();
    const [activeModal, setActiveModal] = useState<ActiveModal>(searchModal);
    const renderContent = () => {
        if (!isSuccess(covid19Response) || !isSuccess(citiesResponse) || !isSuccess(userCountyReviewsResponse)) {
            return <Spinner />;
        }

        return (
            <>
                {ratingKind === 'appRating' ? (
                    <Map covid19={covid19Response.data} />
                ) : (
                    <UserReviewMap reviews={userCountyReviewsResponse.data} />
                )}

                {activeModal.type === 'Search' && (
                    <SearchModal
                        cities={citiesResponse.data}
                        openReview={(city: USCity) => setActiveModal(reviewModal(city))}
                    />
                )}

                {activeModal.type === 'Review' && (
                    <ReviewsModal
                        city={activeModal.city}
                        onClose={() => setActiveModal(searchModal)}
                        onReviewAdd={reloadReview}
                    />
                )}

                <Route path="/user">
                    <UserModal></UserModal>
                </Route>
                <Route path="/statistic">
                    <UserStatistic />
                </Route>

                <Route path="/safe-cities-details" exact>
                    <SafeCitiesDetails />
                </Route>
                <Footer />
            </>
        );
    };

    return (
        <Router history={history}>
            <Switch>
                <Route path="/">{renderContent()}</Route>
            </Switch>
        </Router>
    );
}
