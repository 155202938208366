import _ from 'lodash';
import React from 'react';

import { useService } from 'aidbox-react/lib/hooks/service';

import { Bundle } from 'shared/src/types';
import { defaultSearchSafeCitiesParams, SafeSityBySystem } from 'shared/src/types/safe-cities';

import { RenderFavoriteCities } from 'src/components/RenderFavoriteCities';
import { RenderRemoteData } from 'src/components/RenderRemoteData';
import { UserTextBlock } from 'src/components/UserTextBlock';
import { fetchFavoriteCities } from 'src/services/favoriteCities';

import userFavoriteImage from './favorites.svg';

export function UserFavorites() {
    const [favoriteCitiesResponse] = useService<Bundle<SafeSityBySystem>>(async () => {
        const res = await fetchFavoriteCities({
            ...defaultSearchSafeCitiesParams,
            kind: 'favoriteCities',
        });
        return res;
    });

    return (
        <RenderRemoteData remoteData={favoriteCitiesResponse}>
            {(favoriteCities) => {
                if (favoriteCities.entry.length) {
                    return <RenderFavoriteCities cities={favoriteCities.entry}></RenderFavoriteCities>;
                } else {
                    return (
                        <UserTextBlock
                            img={userFavoriteImage}
                            revieTitle="Here you will see a list of your favorite cities"
                            reviewText="Make a list of cities you want to visit pressing “Add to Favorites” button on a city page"
                            btnText="See Top 10 Low risk cities"
                        ></UserTextBlock>
                    );
                }
            }}
        </RenderRemoteData>
    );
}
