import _ from 'lodash';
import React from 'react';

import { safetyCategories } from '../safety-categories';
import s from './Legend.module.scss';

export function Legend() {
    return (
        <div className={s.container}>
            <div className={s.categories}>
                {_.map(_.cloneDeep(safetyCategories), ({ name, id, color, range }) => {
                    const [min, max] = range;

                    return (
                        <div
                            key={`legend-${id}`}
                            className={s.category}
                            style={{ backgroundColor: color }}
                            title={
                                min
                                    ? `New COVID-19 cases > ${min} per 100.000 population reported for the past two weeks`
                                    : `New COVID-19 cases < ${max} per 100.000 population reported for the past two weeks`
                            }
                        >
                            {name}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
