import * as jwt from 'jsonwebtoken';
import { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';

import { createSharedState } from 'aidbox-react/lib/hooks/shared-state';
import { isSuccess, loading, notAsked, RemoteData, success } from 'aidbox-react/lib/libs/remoteData';
import { setInstanceToken } from 'aidbox-react/lib/services/instance';
import { service } from 'aidbox-react/lib/services/service';

export const authData = createSharedState<RemoteData<GoogleUserInfo>>(notAsked);

export interface GoogleUserInfo {
    id: string;
    email: string;
    verified_email: boolean;
    name: string;
    given_name: string;
    family_name: string;
    picture: string;
    locale: string;
}

export function init() {
    const token = window.localStorage.getItem('token');
    if (token) {
        setInstanceToken({ access_token: token, token_type: 'Bearer' });
        const userInfo: GoogleUserInfo = jwt.decode(token) as any;
        authData.setSharedState(success(userInfo));
    }
}

export async function signIn(data: GoogleLoginResponse | GoogleLoginResponseOffline) {
    authData.setSharedState(loading);
    const response = await service({ url: '/api/signin', data, method: 'POST' });
    if (isSuccess(response)) {
        const token = response.data;
        window.localStorage.token = token;
        setInstanceToken({ access_token: token, token_type: 'Bearer' });
        const userInfo: GoogleUserInfo = jwt.decode(token) as any;
        authData.setSharedState(success(userInfo));
    } else {
        authData.setSharedState(response);
    }
}
