import React from 'react';
import classNames from 'classnames';

import './styles.scss';
import spinnerIcon from './spinner.svg';

interface Props {
    className?: string;
    style?: React.CSSProperties;
}

export function Spinner(props: Props) {
    const { className, style } = props;

    return (
        <div className={classNames('app-spinner', className)} style={style}>
            <img src={spinnerIcon} alt="" />
        </div>
    );
}
