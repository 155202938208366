import _ from 'lodash';
import React from 'react';
import { Route } from 'react-router-dom';

import { UserDeparture } from '../../containers/UserModal/UserDeparture';
import { UserFavorites } from '../../containers/UserModal/UserFavorites';
import { UserHistory } from '../../containers/UserModal/UserHistory';
import { UserPreferences } from '../../containers/UserModal/UserPreferences';
import { UserReviews } from '../../containers/UserModal/UserReviews';
import { MenuItem } from '../MenuItem';
import s from './UserMenu.module.scss';

const userMenu = [
    {
        title: 'Preferences',
        icon: 'search',
        route: `/preferences`,
        component: UserPreferences,
    },
    {
        title: 'History',
        icon: 'history',
        route: `/history`,
        component: UserHistory,
    },
    {
        title: 'Departure',
        icon: 'flight_takeoff',
        route: `/departure`,
        component: UserDeparture,
    },
    {
        title: 'My reviews',
        icon: 'star_half',
        route: `/myreviews`,
        component: UserReviews,
    },
    {
        title: 'Favorites',
        icon: 'favorite',
        route: `/favorites`,
        component: UserFavorites,
    },
];

interface Props {
    url: string;
}
export function UserMenu(props: Props) {
    const { url } = props;

    return (
        <div className={s.wrapper}>
            <nav className={s.menuList}>
                {_.map(userMenu, ({ route, title, icon }, index) => (
                    <MenuItem link={`${url}${route}`} icon={icon} title={title} key={`link-${title}`}></MenuItem>
                ))}
            </nav>
            {_.map(userMenu, ({ route, component }) => (
                <Route path={`${url}${route}`} key={`route-${route}`} component={component}></Route>
            ))}
        </div>
    );
}
