import _ from 'lodash';

import { covid19Ranges } from 'shared/src/utils/covid-19';

import { rangeColors } from 'src/components/rangeColors';

interface Category {
    name: string;
    color: string;
    borderColor: string;
}

const categories: _.Dictionary<Category> = {
    low: {
        name: 'Low',
        color: rangeColors['very-good'].color,
        borderColor: rangeColors['very-good'].dark,
    },
    mild: {
        name: 'Mild',
        color: rangeColors['good'].color,
        borderColor: rangeColors['good'].dark,
    },
    moderate: {
        name: 'Moderate',
        color: rangeColors['average'].color,
        borderColor: rangeColors['average'].dark,
    },
    high: {
        name: 'High',
        color: rangeColors['bad'].color,
        borderColor: rangeColors['bad'].dark,
    },
    critical: {
        name: 'Critical',
        color: rangeColors['very-bad'].color,
        borderColor: rangeColors['very-bad'].dark,
    },
};

export const safetyCategories = _.mapValues(covid19Ranges, (r) => ({
    ...r,
    ...categories[r.id],
}));
