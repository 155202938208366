import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

import { SafeSityBySystem } from 'shared/src/types/safe-cities';

import { useDataRanges, useModal } from 'src/containers/SearchModal';

import { FavoritesCityItem } from '../FavoritesCityItem';
import s from './RenderRemoteData.module.scss';

interface FavoriteCities {
    cities: SafeSityBySystem[];
}

export function RenderFavoriteCities({ cities }: FavoriteCities) {
    const { businessesRange, crimesRange } = useDataRanges();
    const { setCounty } = useModal();

    return (
        <div className={s.citiesList}>
            <div className={s.citiesListHeader}>
                <div className={classNames(s.cityTitleColumn, s.citiesListTitle)}>Favorite cities</div>
                <div className={classNames(s.cityColumn, s._score)}>Score</div>
                <div className={classNames(s.cityColumn, s._covid19)} title="Cases on 100.000 citizens">
                    COVID-19
                </div>
                <div className={classNames(s.cityColumn, s._business)}>Businesses</div>
                <div className={classNames(s.cityColumn, s._crimes)}>Crimes</div>
            </div>
            <>
                {_.map(cities, (safeCity, index) => {
                    return (
                        <FavoritesCityItem
                            safeCity={safeCity}
                            setCounty={setCounty}
                            index={index}
                            businessesRange={businessesRange}
                            crimesRange={crimesRange}
                        />
                    );
                })}
            </>
        </div>
    );
}
