import { CitySummary } from '../utils/review';
import { BusinessesTotal, USCity } from './cities';
import { Covid19 } from './covid-19';
import { CrimesTotal } from './crimes';

interface SafeCityBase {
    city: USCity;
    score: number;
}

export type SafeCityByUser = SafeCityBase & { citySummary: CitySummary };

export type SafeSityBySystem = SafeCityBase & {
    covid19_score: number;
    businesses_score: number;
    crimes_score: number;
    covid19?: Covid19 | null;
    businesses?: BusinessesTotal | null;
    crimes?: CrimesTotal | null;
};

export type SafeCity = SafeCityByUser | SafeSityBySystem;

export function isSafeCityByUser(city: SafeCity): city is SafeCityByUser {
    return city.hasOwnProperty('citySummary');
}

export interface SearchSafeCitiesParams {
    limit: number;
    offset: number;
    text?: string;
}

export const defaultSearchSafeCitiesParams: SearchSafeCitiesParams = {
    limit: 10,
    offset: 0,
};

export type FilterCitiesOptionName =
    | 'temperature'
    | 'humidity'
    | 'condition'
    | 'wind'
    | 'covid_19'
    | 'businesses'
    | 'crimes';

export type ConditionWeatherParams = 'sunny' | 'cloudy' | 'rainy';
export type WindWeatherParams = 'light' | 'medium' | 'strong';

export interface FilterCitiesByWeatherParams {
    temperature?: {
        min: number;
        max: number;
    };
    humidity?: {
        min: number;
        max: number;
    };
    condition?: ConditionWeatherParams[];
    wind?: WindWeatherParams[];
}

export type Covid19StatisticsParams = 'safe' | 'mild' | 'moderate';
export type CrimesStatisticsParams = 'safe' | 'mild' | 'moderate';
export type BusinessesStatisticsParams = 'few' | 'moderate' | 'many';

export interface FilterCitiesByStatisticsParams {
    covid_19?: Covid19StatisticsParams[];
    businesses?: BusinessesStatisticsParams[];
    crimes?: CrimesStatisticsParams[];
}

export interface RatingKind {
    kind?: 'appRating' | 'userRating' | 'favoriteCities';
}

export type RatingKindValue = Required<RatingKind>['kind'];

export type SafeCitiesParams = SearchSafeCitiesParams &
    FilterCitiesByWeatherParams &
    FilterCitiesByStatisticsParams &
    RatingKind;
