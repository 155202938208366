import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Link, Route, useLocation } from 'react-router-dom';

import { StaticTextModal } from 'src/components/StaticTextModal';
import { sharedCounty } from 'src/shared';

import { Legend } from '../Map/Legend';
import s from './Footer.module.scss';

const nav = [
    {
        title: 'Terms and Conditions',
        url: '/terms-and-conditions',
        file: require('src/static-pages/terms-of-service.md'),
    },
    {
        title: 'Privacy Policy',
        url: '/privacy-policy',
        file: require('src/static-pages/privacy-policy.md'),
    },
    {
        title: 'Third party services',
        url: '/third-party-services',
        file: require('src/static-pages/third-party-services.md'),
    },
    {
        title: 'About TravelMooV',
        url: '/about',
        file: require('src/static-pages/about.md'),
    },
    {
        title: 'Statistic',
        url: '/statistic',
    },
];

export function Footer() {
    const location = useLocation();
    const [, setCounty] = sharedCounty.useSharedState();

    return (
        <>
            {_.map(nav, ({ url, title, file }) => {
                if (url !== '/statistic') {
                    return (
                        <Route path={url} key={`route-${url}`}>
                            <StaticTextModal file={file} title={title} />
                        </Route>
                    );
                }
            })}
            <footer className={s.container}>
                <nav className={s.nav}>
                    <div className={classNames(s.navItem, s._logo)}>© TravelMoov, {moment().year()}.</div>
                    {_.map(nav, ({ title, url }, index) => (
                        <Link
                            key={`footer-nav-${index}`}
                            className={classNames(s.navItem, {
                                [s._active]: location.pathname === url,
                            })}
                            to={url}
                            onClick={() => setCounty(null)}
                        >
                            {title}
                        </Link>
                    ))}
                </nav>
                <div className={s.mapLegend}>
                    <Legend />
                </div>
            </footer>
        </>
    );
}
