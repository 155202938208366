import { Range } from './misc';

export const covid19Ranges = {
    low: {
        id: 'low',
        range: [0, 1 * 14],
    },
    mild: {
        id: 'mild',
        range: [1 * 14, 3 * 14],
    },
    moderate: {
        id: 'moderate',
        range: [3 * 14, 11 * 14],
    },
    high: {
        id: 'high',
        range: [11 * 14, 45 * 14],
    },
    critical: {
        id: 'critical',
        range: [45 * 14],
    },
};

export function getCovid19RangesForSearch(): Range[] {
    return [
        {
            id: 'safe',
            range: covid19Ranges.low.range,
        },
        {
            id: 'mild',
            range: [covid19Ranges.mild.range[0], covid19Ranges.moderate.range[1]],
        },
        {
            id: 'moderate',
            range: [covid19Ranges.high.range[0]],
        },
    ];
}
