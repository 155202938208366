import React, { Dispatch, SetStateAction, useState } from 'react';

import { Business, Covid, Crime, ReviewOptions } from 'shared/src/utils/review';

import { Button } from '../Button';
import RatingSelect from '../RatingSelect';
import './styles.scss';

export interface ReviewFormProps {
    submit: (review: ReviewOptions) => Promise<unknown>;
}

type Step = 'One' | 'Two';

function asNumber(s: Step) {
    return s === 'One' ? 1 : 2;
}

function useReviewForm(_props: ReviewFormProps) {
    const [step, setStep] = useState<Step>('One');
    const [review, setReview] = useState<ReviewOptions>({
        business: null,
        crime: null,
        covid: null,
    });
    type KeyName = 'business' | 'crime' | 'covid';

    let checked = false;
    let allchecked = true;

    for (let key in review) {
        if (review[key as KeyName] === null) {
            allchecked = false;
        }
    }

    checked = allchecked;

    return {
        step,
        setStep,
        review,
        setReview,
        checked,
    };
}

export function ReviewForm(props: ReviewFormProps) {
    const { step, setStep, review, setReview, checked } = useReviewForm(props);
    const Component = step === 'One' ? Step1 : Step2;
    return (
        <div className="app-reviewform">
            <p className="app-reviewform__step">Step {asNumber(step)} of 2</p>
            <Component review={review} setReview={setReview} />
            <div className="app-reviewform__submit-wrapper">
                <SubmitButton review={review} step={step} setStep={setStep} submit={props.submit} checked={checked} />
            </div>
        </div>
    );
}

interface SubmitButtonProps {
    step: Step;
    setStep: Dispatch<SetStateAction<Step>>;
    review: ReviewOptions;
    submit: (review: ReviewOptions) => Promise<unknown>;
    checked: boolean;
}

function SubmitButton({ step, setStep, review, submit, checked }: SubmitButtonProps) {
    if (step === 'One') {
        return (
            <Button
                onClick={() => {
                    if (!checked) {
                        return;
                    } else {
                        setStep('Two');
                    }
                }}
                disabled={!checked}
                title={!checked ? 'Please rate all parameters' : ''}
            >
                Continue
            </Button>
        );
    }
    let text = 'Skip and finish';
    if (review.review) {
        text = 'Submit';
    }
    return <Button onClick={() => submit(review)}>{text}</Button>;
}

interface StepProps {
    review: ReviewOptions;
    setReview: Dispatch<SetStateAction<ReviewOptions>>;
}

function Step1({ review, setReview }: StepProps) {
    return (
        <>
            <p className="app-reviewform__step-title">
                How do you rate curent situation in the city according to your experience:
            </p>
            <div className="app-reviewform__selecs-wrapper">
                <RatingSelect
                    options={Object.values(Business)}
                    onChange={(o) => setReview((r) => ({ ...r, business: o as Business }))}
                    label="Opened businesses"
                    tooltip="Opened businesses: How many businesses are currently opened? 1 - Almost all are closed, 5 - Everything is opened."
                />
                <RatingSelect
                    options={Object.values(Covid)}
                    onChange={(o) => setReview((r) => ({ ...r, covid: o as Covid }))}
                    label="COVID-19 cases"
                    tooltip="COVID-19 cases: How many new COVID cases are registered for the past 2 weeks? 1 - Very few, 5 - Drastically increased lately."
                />
                <RatingSelect
                    options={Object.values(Crime)}
                    onChange={(o) => setReview((r) => ({ ...r, crime: o as Crime }))}
                    label="Crime situation"
                    tooltip={`How would you rate the crime situation lately? 1 - It's safe, 5 - It's extremely dangerous.`}
                />
            </div>
        </>
    );
}

function Step2({ review, setReview }: StepProps) {
    return (
        <>
            <p className="app-reviewform__step-title">What has been your impression of Broadview Heights?</p>
            <textarea
                className="app-reviewform__review"
                onChange={(e) => {
                    const review = e.target.value;
                    setReview((r) => ({ ...r, review }));
                }}
            >
                {review.review}
            </textarea>
        </>
    );
}
