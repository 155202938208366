import React from 'react';

import { useService } from 'aidbox-react/lib/hooks/service';
import { isSuccess } from 'aidbox-react/lib/libs/remoteData';

import { BusinessesTotal } from 'shared/src/types/cities';
// import { MainCategory } from 'shared/src/types/yelp';

// import { categoryIcons } from 'src/components/BusinessCategory';
import { WidgetBlock } from 'src/components/WidgetBlock';
import { fetchCityBusinessesTotal } from 'src/services/yelp';


// const radiusBackgroundColor = '#FFD466';
// const radiusForegroundColor = '#F3F3F3';

// type StatisticsCategory = MainCategory & {
//     opened: number;
//     total: number;
// };

interface Props {
    cityId: number;
}

function useCategoriesStatistic({ cityId }: Props) {
    const [businessesTotalResponse] = useService<BusinessesTotal>(async () => fetchCityBusinessesTotal(cityId));
    // const [categoriesResponse, setCategoriesResponse] = useState<RemoteData<StatisticsCategory[]>>(notAsked);

    // useEffect(() => {
    //     (async () => {
    //         setCategoriesResponse(loading);
    //         const response = await fetchCountyCategories(cityId);
    //         setCategoriesResponse(response);
    //     })();
    // }, []); // eslint-disable react-hooks/exhaustive-deps

    return {
        businessesTotalResponse,
    };
}

export function CategoriesWidget(props: Props) {
    const { businessesTotalResponse } = useCategoriesStatistic(props);

    if (!isSuccess(businessesTotalResponse)) {
        return null;
    }

    if (businessesTotalResponse.data) {
        return (
            <WidgetBlock
                title="Business Stats"
                description={`Total opened businesses: ${businessesTotalResponse.data.total}`}
            />
        );
    }

    return null;
    /*
    return (
        <div className={s.container}>
            <div className={s.categories}>
                {categoriesResponse.data.map((category, key) => {
                    const percent = Math.round((category.opened / category.total) * 100);
                    const backgroundImage =
                        percent > 50
                            ? `linear-gradient(90deg, ${radiusBackgroundColor} 50%, transparent 50%), linear-gradient(${
                                  -270 + (100 - percent) * 3.6
                              }deg, ${radiusForegroundColor} 50%, ${radiusBackgroundColor} 50%)`
                            : `linear-gradient(-90deg, ${radiusForegroundColor} 50%, transparent 50%), linear-gradient(${
                                  90 + percent * 3.6
                              }deg, ${radiusBackgroundColor} 50%, ${radiusForegroundColor} 50%)`;
                    const icon = categoryIcons[category.alias];

                    return (
                        <div key={key} className={s.category}>
                            <div className={s.categoryIcon} style={{ backgroundImage }}>
                                <div className={s.categoryIconOverlay}>
                                    <img src={icon} alt="" />
                                </div>
                            </div>

                            <div className={s.statistic}>
                                <div className={`${s.statisticItem} ${s.statisticItem__opened}`}>
                                    <span className={`${s.statisticIcon} ${s.statisticIcon__opened}`}></span>{' '}
                                    {category.opened}
                                </div>
                                <div className={`${s.statisticItem} ${s.statisticItem__total}`}>
                                    <span className={`${s.statisticIcon} ${s.statisticIcon__total}`}></span>{' '}
                                    {category.total}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className={s.comment}>* charts show ratio for total / out of business</div>
        </div>
    );
    */
}
