import classNames from 'classnames';
import React, { useState } from 'react';

import './styles.scss';
import { FullReview } from 'shared/src/utils/review';

export interface Props {
    review: FullReview;
    mode: Mode;
}

type Mode = 'forCity' | 'forProfile';

function formatDate(dateStr: string) {
    const date = new Date(dateStr);
    if (date) {
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    }
    return <></>;
}
export function ReviewCard(props: Props) {
    const { review, mode } = props;
    const [showedReview, setShowedReview] = useState(false);

    return (
        <div
            className={classNames('app-reviewcard ', { 'app-reviewcard--forPerson': mode === 'forProfile' })}
            onClick={(event) => {
                const target = event.target as HTMLElement;
                if (!target.closest('button')) {
                    setShowedReview(!showedReview);
                }
            }}
        >
            <div className="app-reviewcard__header">
                <div className="app-reviewcard__header-person">
                    {mode === 'forCity' && (
                        <>
                            <img src={review.avatar} />
                            {review.reviewerName}
                        </>
                    )}
                    {mode === 'forProfile' && <>{review.cityName}</>}
                </div>
                <div className="app-reviewcard__header-data">{formatDate(review.created_at)}</div>
            </div>
            <div className="app-reviewcard__options">
                <div className="app-reviewcard__option">
                    <div className="app-reviewcard__option-label">Opened businesses:</div>
                    <div className="app-reviewcard__option-value">{review.business}</div>
                </div>
                <div className="app-reviewcard__option">
                    <div className="app-reviewcard__option-label">COVID-19 cases:</div>
                    <div className="app-reviewcard__option-value">{review.covid}</div>
                </div>
                <div className="app-reviewcard__option">
                    <div className="app-reviewcard__option-label">Crimes:</div>
                    <div className="app-reviewcard__option-value">{review.crime}</div>
                </div>
            </div>

            {mode === 'forProfile' && (
                <div className={classNames('app-reviewcard__text-wrapper', { textViewed: showedReview })}>
                    {review.review && <div className="app-reviewcard__text">{review.review}</div>}
                    {/* <button className="app-reviewcard__user-button">Edit</button> */}
                </div>
            )}
            {mode === 'forCity' && <>{review.review && <div className="app-reviewcard__text">{review.review}</div>}</>}
        </div>
    );
}
