export enum Business {
    safe = 'Safe',
    half = 'Half',
    halfAndLess = 'Half & less',
}

export enum Covid {
    safe = 'Safe',
    mild = 'Mild',
    moderate = 'Moderate',
}
export enum Crime {
    safe = 'Safe',
    mild = 'Mild',
    moderate = 'Moderate',
}

export interface Review {
    business: Business;
    crime: Crime;
    covid: Covid;
    review?: string;
}

export interface ReviewOptions {
    business: Business | null;
    crime: Crime | null;
    covid: Covid | null;
    review?: string;
}

export type FullReview = Review & {
    created_at: string;
    avatar: string;
    reviewerName: string;
    cityName: string;
    id: number;
};

export interface CitySummary {
    cityid: number;
    business: Business;
    covid: Covid;
    crime: Crime;
    raiting: string;
}

export interface CountyRating {
    fips: string;
    rating: number;
}

export interface userReviewsParams {
    businesses: Record<Business, number>;
    covid: Record<Covid, number>;
    crimes: Record<Crime, number>;
}

export interface UserReviewsData {
    userReviewsParams: userReviewsParams;
    reviewsCount: number;
}
