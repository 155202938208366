import classNames from 'classnames';
import React from 'react';

import s from './HistoryIcon.module.scss';

interface IconProps {
    iconText: string;
    className?: string;
    title?: string;
}

export function MaterialIcon(props: IconProps) {
    const { iconText, className, title } = props;
    return (
        <span className={classNames(s.icon, className)} title={title}>
            {iconText}
        </span>
    );
}
