import classNames from 'classnames';
import React from 'react';

import s from './CityDataBlock.module.scss';

interface cityDataBlock {
    title: string;
    id: string;
    textIcon?: string;
    imgIcon?: string;
    mainInfo: string;
    disabled?: boolean;
    additionalInfo?: string;
    currentDataWidget: string | undefined;
    onDataWidget: (v: string | undefined) => void;
    onDetails: (v: boolean) => void;
}

export function CityDataBlock(props: cityDataBlock) {
    const {
        title,
        id,
        textIcon,
        imgIcon,
        mainInfo,
        additionalInfo,
        currentDataWidget,
        onDataWidget,
        onDetails,
        disabled,
    } = props;
    return (
        <div
            className={classNames(s.wrapper, { [s._disabled]: disabled })}
            onClick={() => {
                if (disabled) {
                    return;
                }
                if (id === currentDataWidget) {
                    onDetails(false);
                    onDataWidget(undefined);
                } else {
                    onDetails(true);
                    onDataWidget(id);
                }
            }}
        >
            <div className={s.title}>{title}</div>
            {imgIcon ? (
                <div className={s.icon}>
                    <img alt="" width={24} className={classNames(s.weatherIcon, s.imgIcon)} src={imgIcon} />
                </div>
            ) : null}
            {textIcon ? <div className={classNames(s.icon, s.textIcon, s[`textIcon_${id}`])}>{textIcon}</div> : null}
            {id === 'crimesDataWidget' ? (
                <div className={classNames(s.icon)}>
                    <svg
                        className={s.svgIcon}
                        width="14"
                        height="22"
                        viewBox="0 0 14 22"
                        fill="#103040"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7 0C3.13401 0 0 3.13401 0 7V14.1716C0 14.702 0.210713 15.2107 0.585786 15.5858L5.58579 20.5858C6.36684 21.3668 7.63316 21.3668 8.41421 20.5858L13.4142 15.5858C13.7893 15.2107 14 14.702 14 14.1716V7C14 3.13401 10.866 0 7 0ZM4 10C2.89543 10 2 9.10457 2 8H6C6 9.10457 5.10457 10 4 10ZM6 12C4.89543 12 4 12.8954 4 14C4 15.1046 4.89543 16 6 16H8C9.10457 16 10 15.1046 10 14C10 12.8954 9.10457 12 8 12H6ZM8 8C8 9.10457 8.89543 10 10 10C11.1046 10 12 9.10457 12 8H8Z"
                        />
                    </svg>
                </div>
            ) : null}
            <div className={s.infoWrapper}>
                <div className={classNames(s.mainInfo, s.textInfo)}>{mainInfo}</div>
                {additionalInfo ? (
                    <div className={classNames(s.additionalInfo, s.textInfo)}>{additionalInfo}</div>
                ) : null}
            </div>
        </div>
    );
}
