import { line, curveCardinal } from 'd3-shape';
import _ from 'lodash';
import React from 'react';

export interface ChartData {
    date: string;
    cases: number;
}

interface Props {
    width: number;
    height: number;
    strokeWidth?: number;
    data: ChartData[];
}

function useChart(props: Props) {
    const { width, height, data, strokeWidth = 2 } = props;

    const biggestValue = _.maxBy(data, 'cases')!.cases;
    const padding = strokeWidth * 2;

    const path = line<ChartData>()
        .x((d, index) => (width / data.length) * index + padding)
        .y((d) => (d.cases * height) / biggestValue + padding)
        .curve(curveCardinal)(data)!;

    return {
        path: {
            d: path,
            strokeWidth,
        },
        svg: {
            width: width + padding * 2,
            height: height + padding * 2,
        },
    };
}

export function Chart(props: Props) {
    const { width, height } = props;
    const { svg, path } = useChart(props);

    return (
        <div
            style={{
                width,
                height,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: path.strokeWidth,
            }}
        >
            <svg width={svg.width} height={svg.height}>
                <path d={path.d} stroke="#6B8299" strokeWidth={path.strokeWidth} fill="none" strokeLinecap="round" />
            </svg>
        </div>
    );
}
