import React from 'react';

import { useService } from 'aidbox-react/lib/hooks/service';
import { failure, success, isSuccess } from 'aidbox-react/lib/libs/remoteData';

import config from 'shared/src/config';

import { WidgetBlock } from 'src/components/WidgetBlock';

import { CrimeChart } from './Chart';
import { CrimesStatisticPanel } from './StatisticsPanel';
import { CrimeResponse } from './types';

export function useCrime(cityId: number) {
    return useService<CrimeResponse>(
        async () =>
            fetch(config.baseURL + `/api/crimes/?city=${cityId}`)
                .then((response) => response.json())
                .then((data: any) => success(data))
                .catch((err) => failure(err.toString())),
        [cityId],
    );
}

interface Props {
    cityId: number;
    title?: string;
    height?: number;
    width?: number;
}

export function CrimesWidget(props: Props) {
    const { cityId, width = 420, height = 200 } = props;
    const [crimeResponse] = useCrime(cityId);

    if (isSuccess(crimeResponse) && crimeResponse.data.length > 2) {
        const total = crimeResponse.data.reduce((acc, curr) => acc + curr.total, 0);

        return (
            <WidgetBlock title={`Official statistics: ${total} total crime incident`} description={`Last Month`}>
                <CrimesStatisticPanel data={crimeResponse.data} total={total} />
                <CrimeChart width={width} height={height} data={crimeResponse.data} />
            </WidgetBlock>
        );
    } else if (isSuccess(crimeResponse)) {
        return <WidgetBlock title={`No crimes data currently available`} description={`Last Month`} />;
    }

    return null;
}
