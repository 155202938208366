import { service } from 'aidbox-react/lib/services/service';

import { Bundle } from 'shared/src/types';
import { SafeCitiesParams, SafeSityBySystem } from 'shared/src/types/safe-cities';

export async function fetchFavoriteCities(params?: SafeCitiesParams) {
    return service<Bundle<SafeSityBySystem>>({
        url: '/api/userinfo/favoriteCities',
        method: 'GET',
        params: {
            ...params,
            temperature: params?.temperature ? JSON.stringify(params?.temperature) : undefined,
            humidity: params?.humidity ? JSON.stringify(params?.humidity) : undefined,
            condition: params?.condition ? JSON.stringify(params?.condition) : undefined,
            wind: params?.wind ? JSON.stringify(params?.wind) : undefined,
            covid_19: params?.covid_19 ? JSON.stringify(params?.covid_19) : undefined,
            businesses: params?.businesses ? JSON.stringify(params?.businesses) : undefined,
            crimes: params?.crimes ? JSON.stringify(params?.crimes) : undefined,
        },
    });
}

export async function addToFavoriteCity(cityId: number) {
    return service({ url: '/api/userinfo/add-to-favorites', method: 'POST', data: cityId });
}

export async function removeFromFavoriteCity(cityId: number) {
    return service({ url: '/api/userinfo/remove-from-favorites', method: 'POST', data: cityId });
}
