import React from 'react';

import { useService } from 'aidbox-react/lib/hooks/service';

import { fetchCovid19History } from 'src/services/covid-19';

import { CityDataBlock } from '../CityDataBlock';
import { RenderRemoteData } from '../RenderRemoteData';

interface Props {
    fips: string;
    currentDataWidget: string | undefined;
    onDataWidget: (v: string | undefined) => void;
    onDetails: (v: boolean) => void;
}

const roundingCases = (cases: number) => {
    if (cases / 1000 > 0) {
        return `${Math.floor(cases / 1000)}k`;
    } else {
        return cases;
    }
};
export function CovidDataWidget(props: Props) {
    const { fips, currentDataWidget, onDataWidget, onDetails } = props;
    const [covid19HistoryResponse] = useService(async () => fetchCovid19History(fips), [fips]);

    return (
        <RenderRemoteData remoteData={covid19HistoryResponse}>
            {(covidData) => (
                <CityDataBlock
                    title="COVID-19"
                    id="covidDataWidget"
                    textIcon="coronavirus"
                    mainInfo={`${roundingCases(covidData.history[0].cases)}`}
                    additionalInfo={`+${covidData.history[0].cases - covidData.history[1].cases}`}
                    currentDataWidget={currentDataWidget}
                    onDetails={onDetails}
                    onDataWidget={onDataWidget}
                />
            )}
        </RenderRemoteData>
    );
}
