import { MainCategoryAlias } from 'shared/src/types/yelp';

import culturalLifeIcon from './images/cultural-life.svg';
import nightlifeIcon from './images/nightlife.svg';
import parksIcon from './images/parks.svg';
import restaurantsIcon from './images/restaurants.svg';
import shoppingIcon from './images/shopping.svg';
import spaIcon from './images/spa.svg';

type CategoryIcon = {
    [key in MainCategoryAlias]: string;
};

export const categoryIcons: CategoryIcon = {
    food: restaurantsIcon,
    shopping: shoppingIcon,
    nightlife: nightlifeIcon,
    active: parksIcon,
    beautysvc: spaIcon,
    arts: culturalLifeIcon,
};
