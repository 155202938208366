import classNames from 'classnames';
import React from 'react';

import yelpLogo from './Yelp.svg';
import s from './YelpLogo.module.scss';

interface LogoProps {
    className?: string;
}

export function YelpLogo(props: LogoProps) {
    const { className } = props;
    return (
        <a href="https://www.yelp.com/" target="_blank" title="yelp.com">
            <img src={yelpLogo} className={classNames(s.logo, className)} alt="Yelp Logo" />
        </a>
    );
}
