import classNames from 'classnames';
import React, { useState } from 'react';

import { useService } from 'aidbox-react/lib/hooks/service';
import { sequenceMap, service } from 'aidbox-react/lib/services/service';

import { UserReviewsData } from 'shared/src/utils/review';
import { userFiltersStatistic } from 'shared/src/utils/userFilres';

import { MaterialIcon } from 'src/components/HistoryIcon';
import { RenderRemoteData } from 'src/components/RenderRemoteData';
import { history } from 'src/services/history';

import s from './UserStatistic.module.scss';

export function UserStatistic() {
    const [reviewsRemoteData] = useService(() =>
        service<UserReviewsData>({
            url: '/api/reviews',
        }),
    );

    const [filtersRemoteData] = useService(() =>
        service<userFiltersStatistic>({
            url: '/api/filteres',
        }),
    );

    const resultingRemoteData = React.useMemo(
        () =>
            sequenceMap({
                reviews: reviewsRemoteData,
                filters: filtersRemoteData,
            }),
        [reviewsRemoteData, filtersRemoteData],
    );

    const [showTooltp, setShowTooltp] = useState(false);

    return (
        <div className={classNames(s.modal)}>
            <div className={s.container}>
                <div className={s.header}>
                    <span className={s.title}>Statistics</span>
                    <div
                        className={s.ratingInfoWrapper}
                        onMouseOver={(event) => {
                            setShowTooltp(true);
                        }}
                        onMouseLeave={() => setShowTooltp(false)}
                    >
                        <MaterialIcon iconText="help_outline" className={s.ratingInfo} />
                    </div>
                    <button className={s.close} onClick={() => history.push('/')} />
                </div>
                <RenderRemoteData remoteData={resultingRemoteData}>
                    {(resultingData) => (
                        <>
                            <div className={s.statisticBlock}>
                                <span
                                    className={s.statisticBlockTitle}
                                >{`Search Preferences: ${resultingData.filters.usersSetupFilters} users`}</span>
                                <ul className={s.statisticList}>
                                    {Object.entries(resultingData.filters.userFilters).map(([key, value]) => {
                                        return <StatisticItem title={key} obj={value} key={`${key}-statistic`} />;
                                    })}
                                </ul>
                            </div>
                            <div className={s.statisticBlock}>
                                <span
                                    className={s.statisticBlockTitle}
                                >{`Users Provided: ${resultingData.reviews.reviewsCount} reviews`}</span>
                                <ul className={s.statisticList}>
                                    {Object.entries(resultingData.reviews.userReviewsParams).map(([key, value]) => {
                                        return <StatisticItem title={key} obj={value} key={`${key}-statistic`} />;
                                    })}
                                </ul>
                            </div>
                        </>
                    )}
                </RenderRemoteData>

                <div
                    className={classNames(s.toolTip, { [s[`toolTip__displayed`]]: showTooltp })}
                >{`The statistic information is provided for all users in TravelMoov platform. This helps us to analyze the importance of various factors during traveling in pandemic times.`}</div>
            </div>
        </div>
    );
}

interface Props {
    title: string;
    obj: {};
}

function StatisticItem(props: Props) {
    const { title, obj } = props;
    return (
        <li className={s.statisticItem}>
            <div className={s.statisticItemTitle}>{`${title}:`}</div>
            <table className={s.statisticItemTable}>
                <tbody className={s.statisticItemParams}>
                    {Object.entries(obj).map(([key, value]) => {
                        return (
                            <tr key={`${title}-${key}-params`}>
                                <td
                                    className={classNames(s.statisticItemParam, s.staticItemCount)}
                                    key={`${title}-${key}-value`}
                                >{`${value}`}</td>
                                <td
                                    className={classNames(s.statisticItemParam, s.staticItemName)}
                                    key={`${title}-${key}`}
                                >
                                    {key}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </li>
    );
}
