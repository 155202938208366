import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';

import 'src/services/initialize';

import { App } from 'src/containers/App';
import { init } from 'src/services/signin';
import 'src/styles/index.scss';

import * as serviceWorker from './serviceWorker';

ReactGA.initialize(process.env.NODE_ENV === 'production' ? 'UA-191432406-1' : 'test');

init();

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
