import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../Button';
import s from './UserTextBlock.module.scss';
interface Props {
    img: string;
    link?: string;
    revieTitle?: string;
    reviewText?: string;
    btnText: string;
}

export function UserTextBlock(props: Props) {
    const { img, link, revieTitle, reviewText, btnText } = props;

    const history = useHistory();
    function handleClick(link: string | undefined) {
        if (link) {
            history.push(link);
        } else {
            history.push('/');
        }
    }
    return (
        <div className={s.wrapper}>
            <img src={img} className="app-reviews__first-review-image" />
            <p className="app-reviews__first-review-title">{revieTitle}</p>
            <p className="app-reviews__first-review-text">{reviewText}</p>
            <Button className="app-reviews__add-revew-button" onClick={() => handleClick(link)}>
                {btnText}
            </Button>
        </div>
    );
}
