import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';

import { isLoading, isSuccess, loading, notAsked, RemoteData } from 'aidbox-react/lib/libs/remoteData';

import { Bundle } from 'shared/src/types';
import { isSafeCityByUser, SafeCity, SearchSafeCitiesParams } from 'shared/src/types/safe-cities';

import { Button } from 'src/components/Button';
import { SearchInput } from 'src/components/SearchInput';
import { Spinner } from 'src/components/Spinner';
import { fetchSafeCities } from 'src/services/cities';
import { history } from 'src/services/history';

import s from './SafeCitiesDetails.module.scss';

function useSafeCitiesDetails() {
    const defaultParams: SearchSafeCitiesParams = { limit: 100, offset: 0 };
    const [params, setParams] = useState<SearchSafeCitiesParams>(defaultParams);
    const [citiesResponse, setCitiesResponse] = useState<RemoteData<Bundle<SafeCity>>>(notAsked);
    const [cities, setCities] = useState<SafeCity[]>([]);

    useEffect(() => {
        (async () => {
            setCitiesResponse(loading);

            const response = await fetchSafeCities(params);

            if (isSuccess(response)) {
                setCities([...cities, ...response.data.entry]);
            }

            setCitiesResponse(response);
        })();
    }, [params]);

    const loadMore = useCallback(() => {
        setParams({
            ...params,
            offset: params.offset + params.limit,
        });
    }, [params]);

    const onSearch = useCallback(async (text: string) => {
        setCities([]);
        setParams({
            ...defaultParams,
            text,
        });
    }, []);

    return {
        cities,
        citiesResponse,
        loadMore,
        onSearch,
        params,
    };
}

export function SafeCitiesDetails() {
    const { cities, citiesResponse, loadMore, onSearch, params } = useSafeCitiesDetails();

    const renderCities = () => {
        if (params.text && isSuccess(citiesResponse) && !cities.length) {
            return <div>No results found</div>;
        }

        return _.map(cities, (safeCity, index) => {
            if (isSafeCityByUser(safeCity)) {
                return null;
            }
            const { city, covid19, businesses, crimes } = safeCity;

            return (
                <div key={`safe-city-details-${city.id}`} className={s.city}>
                    <div className={s.cityTitleColumn}>{`${index + 1}. ${city.name}, ${city.state.abbr}`}</div>
                    <div className={classNames(s.cityColumn, s._score)}>{safeCity.score}</div>
                    <div className={classNames(s.cityColumn, s._covid19)}>
                        <div className={classNames(s.row, s._black)}>
                            <span className={classNames(s.title, s._short)}>Score:</span>
                            <span className={classNames(s.title, s._long)}>Covid-19 score:</span>
                            <span className={s.result}>{safeCity.covid19_score}</span>
                        </div>
                        <div className={s.row}>
                            <span className={s.title}>Cases normalized:</span>
                            <span className={s.result}>{covid19?.new_cases_normalized}</span>
                        </div>
                        <div className={s.row}>
                            <span className={s.title}>Two weeks cases:</span>
                            <span className={s.result}>{covid19?.new_cases}</span>
                        </div>
                        <div className={s.row}>
                            <span className={s.title}>County population:</span>
                            <span className={s.result}>{city.county.population}</span>
                        </div>
                    </div>
                    <div className={classNames(s.cityColumn, s._business)}>
                        <div className={classNames(s.row, s._black)}>
                            <span className={classNames(s.title, s._short)}>Score:</span>
                            <span className={classNames(s.title, s._long)}>Businesses score:</span>
                            <span className={s.result}>{safeCity.businesses_score}</span>
                        </div>
                        <div className={s.row}>
                            <span className={s.title}>Total normalized:</span>
                            <span className={s.result}>{businesses?.total_normalized}</span>
                        </div>
                        <div className={s.row}>
                            <span className={s.title}>Total:</span>
                            <span className={s.result}>{businesses?.total}</span>
                        </div>
                        <div className={s.row}>
                            <span className={s.title}>City population:</span>
                            <span className={s.result}>{city.population}</span>
                        </div>
                    </div>
                    <div className={classNames(s.cityColumn, s._crimes)}>
                        <div className={classNames(s.row, s._black)}>
                            <span className={classNames(s.title, s._short)}>Score:</span>
                            <span className={classNames(s.title, s._long)}>Crimes score:</span>
                            <span className={s.result}>{safeCity.crimes_score}</span>
                        </div>
                        <div className={s.row}>
                            <span className={s.title}>Total normalized:</span>
                            <span className={s.result}>{crimes?.total_normalized}</span>
                        </div>
                        <div className={s.row}>
                            <span className={s.title}>Total:</span>
                            <span className={s.result}>{crimes?.total}</span>
                        </div>
                        <div className={s.row}>
                            <span className={s.title}>City population:</span>
                            <span className={s.result}>{city.population}</span>
                        </div>
                    </div>
                </div>
            );
        });
    };

    return (
        <div className={s.container}>
            <div className={s.topBar}>
                <SearchInput placeholder="Find city" onChange={onSearch} className={s.search} />
                <button className={s.close} onClick={() => history.push('/')} />
            </div>
            <div className={s.header}>
                <div className={classNames(s.cityTitleColumn, s.headerTitle)}>Cities</div>
                <div className={classNames(s.cityColumn, s._score)}>Safety score</div>
                <div className={classNames(s.cityColumn, s._covid19)} title="Cases on 100.000 citizens">
                    COVID-19 Cases
                </div>
                <div className={classNames(s.cityColumn, s._business)}>Businesses</div>
                <div className={classNames(s.cityColumn, s._crimes)}>Crimes</div>
            </div>
            <div>{renderCities()}</div>
            <div className={s.footer}>
                {isLoading(citiesResponse) ? <Spinner /> : null}
                {isSuccess(citiesResponse) && citiesResponse.data.total !== cities.length ? (
                    <Button onClick={loadMore}>Load more</Button>
                ) : null}
            </div>
        </div>
    );
}
