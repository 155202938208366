import commonConfig from './config.common';

export default {
    ...commonConfig,
    tier: 'production',
    baseURL: 'https://api.travelmoov.com',
    webSentryDSN: 'https://7cb99ef452504723a3997d12f153b129@o509319.ingest.sentry.io/5603609',
    cronSentryDSN: 'https://854e53c7b77b49d1aee93c1f4725ced0@o509319.ingest.sentry.io/5604190',
    backendSentryDSN: 'https://c046cfffbb9943df98be59d68fb6ae47@o509319.ingest.sentry.io/5603602',
};
