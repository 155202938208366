import React, { useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga';

import { useService } from 'aidbox-react/lib/hooks/service';
import { isSuccess } from 'aidbox-react/lib/libs/remoteData';
import { service } from 'aidbox-react/lib/services/service';
import { extractErrorDescription } from 'aidbox-react/lib/utils/error';

import { USCity } from 'shared/src/types/cities';
import { FullReview, CitySummary, ReviewOptions } from 'shared/src/utils/review';

import { Button } from 'src/components/Button';
import { CityRating } from 'src/components/CityRating';
import { Modal } from 'src/components/Modal';
import { RenderRemoteData } from 'src/components/RenderRemoteData/';
import { ReviewCard } from 'src/components/ReviewCard';
import './styles.scss';
import { ReviewForm } from 'src/components/ReviewForm';
import { authData } from 'src/services/signin';
import { sharedCounty } from 'src/shared';

import firstReviewImage from './first-review.svg';

interface ReviewsModalProps {
    city: USCity;
    onClose: () => void;
    onReviewAdd: () => void;
}

export interface FullCityReview {
    cityData?: CitySummary;
    reviews: FullReview[];
    hasReview: boolean;
}

export function ReviewsModal({ city, onClose, onReviewAdd }: ReviewsModalProps) {
    const [county] = sharedCounty.useSharedState();
    useEffect(() => {
        if (county === null) {
            onClose();
        } else if (county.fips != city.county.fips) {
            onClose();
        }
    }, [county, city]);

    const [fullCityReviewRemoteData, { reload }] = useService<FullCityReview>(
        () =>
            service({
                url: `/api/cities/${city.id}/reviews`,
            }),
        [city.id],
    );

    const [userInfo] = authData.useSharedState();
    const [addReviewMode, setAddReviewMode] = useState(false);
    const addReview = useCallback(
        async (data: ReviewOptions) => {
            const response = await service({
                url: `/api/cities/${city.id}/add-review`,
                method: 'POST',
                data,
            });
            if (isSuccess(response)) {
                ReactGA.event({
                    category: 'Review',
                    action: 'review form submit',
                });
                reload();
                onReviewAdd();
                setAddReviewMode(false);
            } else {
                const error = extractErrorDescription(response);
                alert(error);
            }
            return response;
        },
        [reload, city.id, setAddReviewMode],
    );

    const cityName = `${city.name}, ${city.state.abbr}`;

    if (addReviewMode) {
        const title = (
            <div className="app-review__modal-title">
                <div className="app-review__modal-title__header">My review</div>
                <div className="app-review__modal-title__detail">{cityName}</div>
            </div>
        );
        return (
            <div className="modal-wrapper">
                <Modal title={title} onClose={() => setAddReviewMode(false)}>
                    <div className="app-reviews__form-wrapper">
                        {isSuccess(userInfo) ? <ReviewForm submit={addReview} /> : <p> Please login to add a review</p>}
                    </div>
                </Modal>
            </div>
        );
    }
    return (
        <div className="modal-wrapper">
            <Modal title={cityName} onClose={onClose}>
                <RenderRemoteData remoteData={fullCityReviewRemoteData}>
                    {(fullCityReview) => (
                        <>
                            <div className="app-reviews__modal-filter">
                                {fullCityReview.cityData ? (
                                    <>
                                        <CityRating
                                            score={fullCityReview.cityData.raiting}
                                            business={fullCityReview.cityData.business}
                                            crime={fullCityReview.cityData.crime}
                                            covid={fullCityReview.cityData.covid}
                                            reviewsCount={fullCityReview.reviews.length}
                                        />
                                        {!fullCityReview.hasReview && (
                                            <Button
                                                className="app-reviews__add-revew-button"
                                                onClick={() => setAddReviewMode(true)}
                                            >
                                                <span className="material-icons app-reviews__add-revew-icon">
                                                    drive_file_rename_outline
                                                </span>
                                                Add review
                                            </Button>
                                        )}
                                    </>
                                ) : (
                                    <div>
                                        <img src={firstReviewImage} className="app-reviews__first-review-image" />
                                        <p className="app-reviews__first-review-title">
                                            Be the first to leave a review{' '}
                                        </p>
                                        <p className="app-reviews__first-review-text">
                                            Share your travel experience with other TravelMoov users
                                        </p>
                                        <Button
                                            className="app-reviews__add-revew-button"
                                            onClick={() => setAddReviewMode(true)}
                                        >
                                            <span className="material-icons app-reviews__add-revew-icon">
                                                drive_file_rename_outline
                                            </span>
                                            Add review
                                        </Button>
                                    </div>
                                )}
                            </div>
                            {fullCityReview.reviews.length > 0 && (
                                <div className="app-reviews__modal-wrapper">
                                    {fullCityReview.reviews.map((review, index) => (
                                        <ReviewCard review={review} mode="forCity" key={`city-review-${review.id}`} />
                                    ))}
                                </div>
                            )}
                        </>
                    )}
                </RenderRemoteData>
            </Modal>
        </div>
    );
}
