import _ from 'lodash';

import { isSuccess, success } from 'aidbox-react/lib/libs/remoteData';
import { service } from 'aidbox-react/lib/services/service';

import { BusinessesTotal } from 'shared/src/types/cities';
import {
    BusinessBundle,
    BusinessDetails,
    BusinessReviewBundle,
    mainCategories,
    MainCategory,
    BusinessSearchParams,
} from 'shared/src/types/yelp';

export async function fetchBusiness(id: string) {
    return service<BusinessDetails>({
        url: `/api/yelp/business/${id}`,
        method: 'GET',
    });
}

export async function fetchBusinessReviews(id: string) {
    return service<BusinessReviewBundle>({
        url: `/api/yelp/business/${id}/reviews`,
        method: 'GET',
    });
}

export async function searchBusinesses(params: BusinessSearchParams) {
    return service<BusinessBundle>({
        url: '/api/yelp/businesses/search',
        method: 'GET',
        params: {
            ...params,
            categories: params.categories?.length ? _.join(params.categories, ',') : undefined,
            price: params.price?.length ? _.join(params.price, ',') : undefined,
        },
    });
}

interface CategoryStatistics {
    category: string;
    total: number;
}

export async function fetchCountyCategories(cityId: number) {
    const response = await service<any>({
        url: `/api/yelp/categories`,
        method: 'GET',
        params: {
            city: cityId,
        },
    });

    if (isSuccess(response)) {
        const { data } = response;
        const categoryMap: _.Dictionary<MainCategory> = _.chain(mainCategories)
            .map((c) => [c.alias, c])
            .fromPairs()
            .value();

        return success(
            data.map((category: CategoryStatistics) => ({
                ...categoryMap[category.category],
                total: category.total,
            })),
        );
    }

    return response;
}

export async function fetchCityBusinessesTotal(id: number) {
    return service<BusinessesTotal>({
        url: `/api/yelp/city-businesses/${id}/total`,
        method: 'GET',
    });
}

export async function fetchBusinessesTotalRange() {
    return service<BusinessesTotal[] | undefined[]>({
        url: `/api/yelp/businesses/total-range`,
        method: 'GET',
    });
}
