import classNames from 'classnames';
import React, { useCallback, useState } from 'react';

import './styles.scss';

interface Props {
    placeholder?: string;
    className?: string;
    onChange: (v: string) => void;
}

function useSearchInput(props: Props) {
    const { onChange } = props;
    const [value, setValue] = useState<string>('');
    let timeout: NodeJS.Timeout | undefined;

    const resetValue = useCallback(() => {
        onChange('');
        setValue('');
    }, [onChange]);

    const onValueChange = useCallback(
        (value: string) => {
            setValue(value);

            if (timeout) {
                clearTimeout(timeout);
            }

            if (value && value.length <= 2) {
                return;
            }

            timeout = setTimeout(() => {
                onChange(value);

                if (timeout) {
                    clearTimeout(timeout);
                }
            }, 400);
        },
        [onChange],
    );

    return { value, onValueChange, resetValue };
}

export function SearchInput(props: Props) {
    const { value, onValueChange, resetValue } = useSearchInput(props);
    const { className, placeholder } = props;

    return (
        <div className={classNames('app-search', className)}>
            <div className="app-search__icon" />
            <input
                className="app-search__input"
                placeholder={placeholder}
                type="text"
                value={value}
                onChange={(e) => onValueChange(e.target.value)}
            />
            {value ? <button className="app-search__reset" onClick={resetValue} /> : null}
        </div>
    );
}
