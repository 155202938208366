import React from 'react';

import { isSuccess, RemoteData } from 'aidbox-react/lib/libs/remoteData';

import { useMobility } from 'src/containers/CountyInfo/MobilityWidget';
import { MobilityData } from 'src/containers/CountyInfo/MobilityWidget/types';

import { CityDataBlock } from '../CityDataBlock';
import { RenderRemoteData } from '../RenderRemoteData';

interface Props {
    county: string;
    currentDataWidget: string | undefined;
    onDataWidget: (v: string | undefined) => void;
    onDetails: (v: boolean) => void;
}

interface mobilityObj {
    icon: string;
    last: number;
    prev: number;
}

function callcMaxMobility(mobilityResponse: RemoteData<MobilityData[], any>) {
    const maxDifference = 0;
    let mobilityObj: mobilityObj = { icon: 'do_not_disturb ', last: 0, prev: 0 };

    if (isSuccess(mobilityResponse)) {
        mobilityResponse.data.forEach((element) => {
            const iMaxDifference = Math.round(
                Math.abs(100 - Number(element.statistics[element.statistics.length - 1].value)),
            );
            if (iMaxDifference > maxDifference) {
                if (element.type === 'walking') {
                    mobilityObj.icon = 'directions_walk';
                }
                if (element.type === 'transit') {
                    mobilityObj.icon = 'directions_transit';
                }
                if (element.type === 'driving') {
                    mobilityObj.icon = 'directions_car';
                }
                mobilityObj = {
                    ...mobilityObj,
                    last: iMaxDifference,
                    prev: Math.round(Math.abs(100 - Number(element.statistics[element.statistics.length - 2].value))),
                };
            }
        });
    }
    return mobilityObj;
}

export function MobilityDataWidget(props: Props) {
    const { county, currentDataWidget, onDataWidget, onDetails } = props;
    const [mobilityResponse] = useMobility(county);
    const mobilityObj = callcMaxMobility(mobilityResponse);

    return (
        <RenderRemoteData remoteData={mobilityResponse}>
            {(mobilityData) => (
                <>
                    {mobilityData.length >= 1 ? (
                        <CityDataBlock
                            title="Mobility"
                            id="mobilityDataWidget"
                            textIcon={mobilityObj.icon}
                            mainInfo={`${mobilityObj.last}%`}
                            additionalInfo={`${mobilityObj.prev}%`}
                            currentDataWidget={currentDataWidget}
                            onDetails={onDetails}
                            onDataWidget={onDataWidget}
                        />
                    ) : (
                        <CityDataBlock
                            title="Mobility"
                            id="mobilityDataWidget"
                            disabled
                            textIcon={mobilityObj.icon}
                            mainInfo={`--%`}
                            additionalInfo={`--%`}
                            currentDataWidget={currentDataWidget}
                            onDetails={onDetails}
                            onDataWidget={onDataWidget}
                        />
                    )}
                </>
            )}
        </RenderRemoteData>
    );
}
