import classNames from 'classnames';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import GoogleLogin from 'react-google-login';
import { Link, Route, useLocation } from 'react-router-dom';

import { useService } from 'aidbox-react/lib/hooks/service';
import { isLoading, isNotAsked, isSuccess, loading, notAsked, RemoteData } from 'aidbox-react/lib/libs/remoteData';

import config from 'shared/src/config';
import { Bundle } from 'shared/src/types';
import { USCity, USCounty } from 'shared/src/types/cities';
import {
    defaultSearchSafeCitiesParams,
    FilterCitiesByStatisticsParams,
    FilterCitiesByWeatherParams,
    FilterCitiesOptionName,
    isSafeCityByUser,
    SafeCity,
    SafeCityByUser,
    SafeSityBySystem,
} from 'shared/src/types/safe-cities';
import { isValueInRange, getRange } from 'shared/src/utils/misc';
import { Business, Covid, Crime } from 'shared/src/utils/review';

import { Button } from 'src/components/Button';
import { rangeColors } from 'src/components/rangeColors';
import { RatingSwitcher } from 'src/components/RatingSwitcher';
import { SearchInput } from 'src/components/SearchInput';
import { Spinner } from 'src/components/Spinner';
import { StaticTextModal } from 'src/components/StaticTextModal';
import { fetchSafeCities } from 'src/services/cities';
import { fetchCrimesTotalRange } from 'src/services/crimes';
import { history } from 'src/services/history';
import { authData, signIn } from 'src/services/signin';
import { getUserPreferences } from 'src/services/userPreferences';
import { fetchBusinessesTotalRange } from 'src/services/yelp';
import { sharedCitiesList, sharedCounty, sharedFilters, sharedFiltersApply, sharedRatingKind } from 'src/shared';
import { getCitiesFilterAnalyticsParams } from 'src/utils/analytics';

import { UserAvatar } from '../../components/UserAvatar';
import { safetyCategories } from '../Map/safety-categories';
import { BusinessList } from './BusinessList';
import { CitiesFilters } from './CitiesFilters';
import logo from './images/logo.svg';
import s from './SearchModal.module.scss';

const scoreCalculationPath = '/score-calculation';

export function useModal() {
    const location = useLocation();
    const [county, setCounty] = sharedCounty.useSharedState();
    const [opened, setOpened] = useState(!county);

    useEffect(() => {
        if (county) {
            setOpened(true);
            history.push('/');
        }
    }, [county]);

    React.useEffect(() => {
        if (location.pathname !== '/') {
            setOpened(false);
        }
    }, [location]);

    React.useEffect(() => {
        if (location.pathname == '/') {
            setOpened(true);
        }
    }, [location]);

    return {
        county,
        setCounty,
        opened,
        setOpened: (v: boolean) => {
            setOpened(v);
            setCounty(null);

            if (v && location.pathname !== '/') {
                history.push('/');
            }
        },
        location,
    };
}

function useSearch(cities: SafeCity[]) {
    const [searchResponse, setSearchResponse] = useState<RemoteData<Bundle<SafeCity>>>(notAsked);
    const [searchCities, setSearchCities] = useState<SafeCity[]>([]);
    const [ratingKind] = sharedRatingKind.useSharedState();

    const resetResults = useCallback(() => {
        setSearchResponse(notAsked);
        setSearchCities([]);
        sharedCitiesList.setSharedState(cities);
    }, [cities]);

    const onSearch = useCallback(
        async (text: string) => {
            if (text) {
                setSearchResponse(loading);

                ReactGA.event({
                    category: 'Cities list',
                    action: 'Search cities',
                    label: text,
                });

                const response = await fetchSafeCities({
                    ...defaultSearchSafeCitiesParams,
                    text,
                    kind: ratingKind,
                });

                if (isSuccess(response)) {
                    sharedCitiesList.setSharedState(response.data.entry);
                    setSearchCities(response.data.entry);
                }

                setSearchResponse(response);
            } else {
                resetResults();
            }
        },
        [resetResults, ratingKind],
    );

    const onFilter = useCallback(
        async (params?: FilterCitiesByWeatherParams & FilterCitiesByStatisticsParams) => {
            if (params && !_.isEmpty(params)) {
                setSearchResponse(loading);

                _.forEach(_.keys(params), (key) => {
                    const analyticsParams = getCitiesFilterAnalyticsParams(key as FilterCitiesOptionName, params);

                    if (analyticsParams) {
                        ReactGA.event({
                            category: 'Cities list',
                            action: `Filter by ${analyticsParams.action}`,
                            label: analyticsParams.label,
                        });
                    }
                });

                const response = await fetchSafeCities({
                    ...defaultSearchSafeCitiesParams,
                    ...params,
                    kind: ratingKind,
                });

                if (isSuccess(response)) {
                    sharedCitiesList.setSharedState(response.data.entry);
                    setSearchCities(response.data.entry);
                }

                setSearchResponse(response);
            } else {
                resetResults();
            }
        },
        [resetResults, ratingKind],
    );

    return { searchCities, searchResponse, onSearch, onFilter };
}

export function useDataRanges() {
    const [businessesTotalRangeResponse] = useService(async () => await fetchBusinessesTotalRange());
    const [crimesTotalRangeResponse] = useService(async () => await fetchCrimesTotalRange());

    const [businessesRange, setBusinessesRange] = useState<number[][]>();
    const [crimesRange, setCrimesRange] = useState<number[][]>();

    useEffect(() => {
        if (isSuccess(businessesTotalRangeResponse)) {
            const [min, max] = businessesTotalRangeResponse.data;

            if (min && max) {
                const range = getRange(min.total_normalized, max.total_normalized);

                setBusinessesRange(range);
            }
        }
    }, [businessesTotalRangeResponse]);

    useEffect(() => {
        if (isSuccess(crimesTotalRangeResponse)) {
            const [min, max] = crimesTotalRangeResponse.data;

            if (min && max) {
                const range = getRange(min.total_normalized, max.total_normalized);

                setCrimesRange(range);
            }
        }
    }, [crimesTotalRangeResponse]);

    return {
        businessesRange,
        crimesRange,
    };
}

interface Props {
    cities: Bundle<SafeCity>;
    openReview: (city: USCity) => void;
}

export function SearchModal(props: Props) {
    const { cities, openReview } = props;
    const [filtersOpened, setFiltersOpened] = useState<boolean>(false);
    const { county, opened, setCounty, setOpened, location } = useModal();
    const { searchCities, searchResponse, onSearch, onFilter } = useSearch(cities.entry);
    const { businessesRange, crimesRange } = useDataRanges();
    const [userInfo] = authData.useSharedState();
    const [ratingKind, setRatingKind] = sharedRatingKind.useSharedState();
    const [filters, setFilters] = sharedFilters.useSharedState();
    const [filtersSetup, setFiltersSetup] = sharedFiltersApply.useSharedState();

    const [userDataResponse, { reload }] = useService(async () => await getUserPreferences(), [userInfo]);

    useEffect(() => {
        if (!isSuccess(userInfo) && isSuccess(userDataResponse) && !filtersSetup) {
            reload();
        }

        if (isSuccess(userInfo) && isSuccess(userDataResponse) && !filtersSetup) {
            if (userDataResponse.data) {
                setFilters(JSON.parse(userDataResponse.data.user_filters));
                setFiltersSetup(true);
            }
        }
    }, [userDataResponse, userInfo]);

    useEffect(() => {
        onFilter(filters);
        renderContent();
    }, [ratingKind, filters]);

    const showSafeCitiesList = isNotAsked(searchResponse);
    const isSearchResultsLoading = isLoading(searchResponse);

    const renderCitiesList = (cities: SafeCity[]) => {
        return _.map(cities, (safeCity, index) => {
            if (isSafeCityByUser(safeCity)) {
                return <SafeCityByUserRow safeCity={safeCity} setCounty={setCounty} index={index} />;
            } else {
                return (
                    <SafeCityBySystemRow
                        safeCity={safeCity}
                        setCounty={setCounty}
                        index={index}
                        businessesRange={businessesRange}
                        crimesRange={crimesRange}
                    />
                );
            }
        });
    };

    const renderSearchResults = () => {
        if (isSearchResultsLoading && !searchCities.length) {
            return null;
        }

        if (searchCities.length) {
            return renderCitiesList(searchCities);
        }

        return <div>No results found</div>;
    };

    const renderCitiesContainer = () => {
        return (
            <div className={s.citiesList}>
                <div className={s.citiesListHeader}>
                    <div className={classNames(s.cityTitleColumn, s.citiesListTitle)}>
                        <div
                            className={classNames(s.citiesListTitleIcon, {
                                [s._search]: !showSafeCitiesList,
                                [s._loading]: isSearchResultsLoading,
                            })}
                        >
                            {isSearchResultsLoading ? <Spinner className={s.spinner} /> : null}
                        </div>
                        {showSafeCitiesList ? 'Top 10 Low risk cities' : 'Search results'}
                        {showSafeCitiesList ? (
                            <Link to={scoreCalculationPath} onClick={() => setOpened(false)}>
                                <div className={s.scoreCalculationButton} />
                            </Link>
                        ) : null}
                    </div>
                    <div className={classNames(s.cityColumn, s._score)}>Score</div>
                    <div className={classNames(s.cityColumn, s._covid19)} title="Cases on 100.000 citizens">
                        COVID-19
                    </div>
                    <div className={classNames(s.cityColumn, s._business)}>Businesses</div>
                    <div className={classNames(s.cityColumn, s._crimes)}>Crimes</div>
                </div>
                {showSafeCitiesList ? renderCitiesList(cities.entry) : renderSearchResults()}
            </div>
        );
    };

    const renderContent = () => {
        if (!opened) {
            return null;
        }

        if (county) {
            return <BusinessList county={county} onClose={() => setCounty(null)} openReview={openReview} />;
        }

        return (
            <>
                <RatingSwitcher value={ratingKind} onChange={setRatingKind} />

                <CitiesFilters
                    isSearchResultsLoading={isSearchResultsLoading}
                    opened={filtersOpened}
                    onFiltersToggle={(v) => setFiltersOpened(v)}
                    onSubmit={onFilter}
                />

                {filtersOpened ? null : renderCitiesContainer()}
            </>
        );
    };

    const renderSearch = () => {
        if (location.pathname !== '/') {
            return null;
        }

        return (
            <div className={classNames(s.search, { [s._county]: !!county })}>
                <SearchInput placeholder="Find city" onChange={onSearch} />
            </div>
        );
    };

    return (
        <>
            <div
                className={classNames(s.modal, {
                    [s._opened]: !!opened,
                    [s._county]: !!county,
                })}
            >
                <div className={s.container}>
                    <div className={s.header}>
                        <Link
                            to={'/'}
                            className={s.logo}
                            onClick={() => {
                                setOpened(true);
                            }}
                        >
                            <img src={logo} alt="" />
                        </Link>
                        {renderSearch()}
                        {isSuccess(userInfo) ? (
                            <Link
                                to="/user/preferences"
                                onClick={() => {
                                    ReactGA.event({
                                        category: 'user-profile',
                                        action: 'open',
                                    });
                                    setOpened(false);
                                }}
                            >
                                <UserAvatar imgLink={userInfo.data.picture} mode="serchModal"></UserAvatar>
                            </Link>
                        ) : (
                            <GoogleLogin
                                clientId={config.clientId}
                                render={(renderProps) => (
                                    <Button
                                        className={s.loginButton}
                                        onClick={() => {
                                            ReactGA.event({
                                                category: 'Login',
                                                action: 'Login',
                                            });
                                            renderProps.onClick();
                                        }}
                                    >
                                        <div className={s.loginIcon}></div>
                                        Log in
                                    </Button>
                                )}
                                buttonText="Login"
                                onSuccess={(response) => signIn(response)}
                                onFailure={(error) => console.log(error)}
                                cookiePolicy={'single_host_origin'}
                            />
                        )}
                    </div>
                    {renderContent()}
                </div>
            </div>
            <Route path={scoreCalculationPath}>
                <StaticTextModal file={require('src/static-pages/safety-score.md')} title={'How we calculate'} />
            </Route>
        </>
    );
}

interface IndicatorProps {
    value?: number;
    range: number[][];
    colors: string[];
    title?: string;
}

function Indicator(props: IndicatorProps) {
    const { value, range, colors, title } = props;

    if (!_.isNumber(value)) {
        return <div className={s.dash}></div>;
    }

    if (colors.length !== range.length) {
        console.warn('<Indicator /> colors and range should contain equal number of items');

        return null;
    }

    const colorIndex = _.findIndex(range, (r) => isValueInRange(value, r));

    return <div className={s.circle} style={{ backgroundColor: colors[colorIndex] }} title={title}></div>;
}

interface SafeCityBySystemRowProps {
    safeCity: SafeSityBySystem;
    setCounty: (s: USCounty | null) => void;
    index: number;
    businessesRange?: number[][];
    crimesRange?: number[][];
}

export function SafeCityBySystemRow({
    safeCity,
    setCounty,
    index,
    businessesRange,
    crimesRange,
}: SafeCityBySystemRowProps) {
    const { city, covid19, businesses, crimes, score } = safeCity;
    const { county } = city;

    return (
        <button
            key={`safe-cities-${city.id}`}
            className={s.city}
            onClick={() => {
                ReactGA.event({
                    category: 'Cities list',
                    action: 'City clicked',
                    label: `${city.name}, ${city.state.abbr}`,
                });
                setCounty({
                    ...county,
                    state: city.state,
                });
            }}
        >
            <div className={s.cityTitleColumn}>{`${index + 1}. ${city.name}, ${city.state.abbr}`}</div>
            <div className={classNames(s.cityColumn, s._score)}>{score}</div>
            <div className={classNames(s.cityColumn, s._covid19)}>
                <Indicator
                    value={covid19?.new_cases_normalized}
                    range={_.map(safetyCategories, ({ range }) => range)}
                    colors={_.map(_.mapValues(rangeColors), (c) => c.color)}
                    title={
                        _.isNumber(covid19?.new_cases_normalized)
                            ? `${covid19?.new_cases_normalized} new COVID-19 cases per 100.000 county population reported for the past two weeks`
                            : undefined
                    }
                />
            </div>
            <div className={classNames(s.cityColumn, s._business)}>
                {renderBusinessesIndicator(businesses?.total_normalized, businessesRange)}
            </div>
            <div className={classNames(s.cityColumn, s._crimes)}>
                {renderCrimesIndicator(crimes?.total_normalized, crimesRange)}
            </div>
        </button>
    );
}

const renderBusinessesIndicator = (total_normalized: number | undefined, businessesRange: number[][] | undefined) => {
    if (!businessesRange) {
        return null;
    }

    return (
        <Indicator
            value={total_normalized}
            range={businessesRange}
            colors={[rangeColors['very-bad'].color, rangeColors.good.color, rangeColors['very-good'].color]}
            title={
                _.isNumber(total_normalized)
                    ? `${total_normalized} opened businesses per 100.000 city population`
                    : undefined
            }
        />
    );
};

const renderCrimesIndicator = (total_normalized: number | undefined, crimesRange: number[][] | undefined) => {
    if (!crimesRange) {
        return null;
    }

    return (
        <Indicator
            value={total_normalized}
            range={crimesRange}
            colors={[rangeColors['very-good'].color, rangeColors.good.color, rangeColors['very-bad'].color]}
            title={
                _.isNumber(total_normalized)
                    ? `${total_normalized} crimes happened per 100.000 city population last month`
                    : undefined
            }
        />
    );
};

interface SafeCityByUserRowProps {
    safeCity: SafeCityByUser;
    setCounty: (s: USCounty | null) => void;
    index: number;
}

export function SafeCityByUserRow({ safeCity, setCounty, index }: SafeCityByUserRowProps) {
    const { city, score } = safeCity;
    const { county } = city;
    return (
        <button
            key={`safe-cities-${city.id}`}
            className={s.city}
            onClick={() => {
                ReactGA.event({
                    category: 'Cities list',
                    action: 'City clicked',
                    label: `${city.name}, ${city.state.abbr}`,
                });
                setCounty({
                    ...county,
                    state: city.state,
                });
            }}
        >
            <div className={s.cityTitleColumn}>{`${index + 1}. ${city.name}, ${city.state.abbr}`}</div>
            <div className={classNames(s.cityColumn, s._score)}>{score}</div>
            <div className={classNames(s.cityColumn, s._covid19)}>
                <IndicatorForUserReview value={safeCity.citySummary.covid} />
            </div>
            <div className={classNames(s.cityColumn, s._business)}>
                <IndicatorForUserReview value={safeCity.citySummary.business} />
            </div>
            <div className={classNames(s.cityColumn, s._crimes)}>
                <IndicatorForUserReview value={safeCity.citySummary.crime} />
            </div>
        </button>
    );
}

interface IndicatorForUserReviewProps {
    value: Business | Covid | Crime;
}

function IndicatorForUserReview({ value }: IndicatorForUserReviewProps) {
    const colors = [rangeColors['very-good'].color, rangeColors.good.color, rangeColors['very-bad'].color];
    let colorIndex = 2;
    if (value === 'Mild' || value == 'Half') {
        colorIndex = 1;
    } else if (value === 'Safe') {
        colorIndex = 0;
    }
    return <div className={s.circle} style={{ backgroundColor: colors[colorIndex] }}></div>;
}
