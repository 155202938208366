import _ from 'lodash';
import React, { ReactNode, useCallback, useState } from 'react';

import {
    BusinessesStatisticsParams,
    ConditionWeatherParams,
    Covid19StatisticsParams,
    CrimesStatisticsParams,
    FilterCitiesByStatisticsParams,
    FilterCitiesByWeatherParams,
    WindWeatherParams,
} from 'shared/src/types/safe-cities';

import { Button } from 'src/components/Button';
import { ChoiceInput } from 'src/components/form/ChoiceInput';
import { Slider } from 'src/components/form/Slider';
import {
    businessesValues,
    conditionValues,
    covid19Values,
    crimesValues,
    windValues,
} from 'src/containers/SearchModal/CitiesFilters/values';
import { sharedFilters, sharedFiltersApply } from 'src/shared';

import s from './UserFilters.module.scss';

interface Props {
    filters?: FilterCitiesByWeatherParams & FilterCitiesByStatisticsParams;
    onSubmit: (
        filter: FilterCitiesByWeatherParams & FilterCitiesByStatisticsParams,
        userProp: 'user_filters' | 'cityId',
    ) => void;
    userProp: 'user_filters' | 'cityId';
}

function useCitiesFilters(filters: (FilterCitiesByWeatherParams & FilterCitiesByStatisticsParams) | undefined) {
    const [filterUpdated, setFilterUpdated] = useState<boolean>(false);
    const [filter, setFilter] = useState<FilterCitiesByWeatherParams & FilterCitiesByStatisticsParams>(filters || {});

    const onValueChange = useCallback((v) => {
        setFilter(v);
        setFilterUpdated(true);
    }, []);

    return { filter, setFilter, onValueChange, filterUpdated, setFilterUpdated };
}

export function UserFilters(props: Props) {
    const { onSubmit, userProp } = props;
    const { filter, onValueChange, filterUpdated, setFilterUpdated } = useCitiesFilters(props.filters);

    const [, setFilters] = sharedFilters.useSharedState();
    const [, setFiltersSetup] = sharedFiltersApply.useSharedState();

    return (
        <div className={s.content}>
            <Filter
                title="Temperature, °F"
                renderFilter={() => (
                    <Slider
                        value={filter.temperature}
                        min={0}
                        max={120}
                        onChange={(v) => {
                            onValueChange({
                                ...filter,
                                temperature: v,
                            });
                        }}
                    />
                )}
            />
            <Filter
                title="Humidity, %"
                renderFilter={() => (
                    <Slider
                        value={filter.humidity}
                        min={30}
                        max={90}
                        onChange={(v) => {
                            onValueChange({
                                ...filter,
                                humidity: v,
                            });
                        }}
                    />
                )}
            />
            <Filter
                title="Condition"
                renderFilter={() => (
                    <ChoiceInput<ConditionWeatherParams | undefined>
                        name="condition"
                        value={filter.condition}
                        values={conditionValues}
                        onChange={(v) =>
                            onValueChange({
                                ...filter,
                                condition: v,
                            })
                        }
                        multiple
                    />
                )}
            />
            <Filter
                title="Wind"
                renderFilter={() => (
                    <ChoiceInput<WindWeatherParams | undefined>
                        name="wind"
                        value={filter.wind}
                        values={windValues}
                        onChange={(v) =>
                            onValueChange({
                                ...filter,
                                wind: v,
                            })
                        }
                        multiple
                    />
                )}
            />
            <Filter
                title="Businesses"
                renderFilter={() => (
                    <ChoiceInput<BusinessesStatisticsParams | undefined>
                        name="businesses"
                        value={filter.businesses}
                        values={businessesValues}
                        onChange={(v) =>
                            onValueChange({
                                ...filter,
                                businesses: v,
                            })
                        }
                        multiple
                    />
                )}
            />
            <Filter
                title="COVID-19 cases"
                renderFilter={() => (
                    <ChoiceInput<Covid19StatisticsParams | undefined>
                        name="covid-19"
                        value={filter.covid_19}
                        values={covid19Values}
                        onChange={(v) =>
                            onValueChange({
                                ...filter,
                                covid_19: v,
                            })
                        }
                        multiple
                    />
                )}
            />
            <Filter
                title="Crimes"
                renderFilter={() => (
                    <ChoiceInput<CrimesStatisticsParams | undefined>
                        name="crimes"
                        value={filter.crimes}
                        values={crimesValues}
                        onChange={(v) =>
                            onValueChange({
                                ...filter,
                                crimes: v,
                            })
                        }
                        multiple
                    />
                )}
            />
            <div className={s.footer}>
                <Button
                    onClick={() => {
                        setFilterUpdated(false);
                        onSubmit(clearEmtptyFilters(filter), userProp);
                        setFilters(clearEmtptyFilters(filter));
                        setFiltersSetup(false);
                    }}
                    disabled={!filterUpdated}
                >
                    Save as default filters
                </Button>
            </div>
        </div>
    );
}

interface FilterProps {
    title: string;
    renderFilter: () => ReactNode;
}

function Filter(props: FilterProps) {
    const { title, renderFilter } = props;

    return (
        <div className={s.filter}>
            <div className={s.filterTitle}>{title}</div>
            <div className={s.filterField}>{renderFilter()}</div>
        </div>
    );
}

function clearEmtptyFilters(filteres: any) {
    const clearFilters = filteres;

    for (var key in clearFilters) {
        if (clearFilters[key].length === 0) {
            delete clearFilters[key];
        }

        if (key === 'temperature') {
            if (clearFilters[key].min <= 0 && clearFilters[key].max >= 120) {
                delete clearFilters[key];
            }
        }

        if (key === 'humidity') {
            if (clearFilters[key].min <= 30 && clearFilters[key].max >= 90) {
                delete clearFilters[key];
            }
        }
    }

    if (!Object.keys(clearFilters).length) {
        return {};
    }

    return clearFilters;
}
