import classNames from 'classnames';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import './styles.scss';

export interface SliderValue {
    min: number;
    max: number;
}

interface Props {
    value?: SliderValue;
    min: number;
    max: number;
    onChange: (v: SliderValue) => void;
}

interface SliderContentProps extends Props {
    width: number;
    offset: number;
}

function useSliderContent(props: SliderContentProps) {
    const { min, max, width, onChange } = props;
    const diff = max - min;
    const thumbSize = 16;
    const maxWidth = width - thumbSize;

    const [value, setValue] = useState<SliderValue>(props.value || { min, max });

    const toPixels = useCallback(
        (value: number) => {
            const left = _.round(((value - min) / diff) * maxWidth);

            return left;
        },
        [min, diff, maxWidth],
    );

    const toValue = useCallback(
        (valueInPixels: number) => {
            const value = _.round((valueInPixels / maxWidth) * diff + min);

            return value;
        },
        [min, diff, maxWidth],
    );

    const onValueChange = useCallback(
        (v: SliderValue) => {
            setValue(v);
            onChange(v);
        },
        [onChange],
    );

    return { value, onValueChange, setValue, toValue, toPixels };
}

export function SliderContent(props: SliderContentProps) {
    const { offset, min, max, width } = props;
    const { value, onValueChange, setValue, toValue, toPixels } = useSliderContent(props);

    return (
        <div className="slider">
            <div className="slider__lines">
                <div className="slider__line" />
                <div
                    className="slider__line _colored"
                    style={{ left: toPixels(value.min) + 8, right: width - toPixels(value.max) - 8 }}
                />
            </div>
            <SliderThumb
                className="_start"
                defaultValue={toPixels(value.min)}
                min={toPixels(min)}
                max={toPixels(value.max)}
                offset={offset}
                toValue={toValue}
                onMove={(min: number) => setValue({ ...value, min })}
                onChange={(min: number) => onValueChange({ ...value, min })}
            />
            <SliderThumb
                className="_end"
                defaultValue={toPixels(value.max)}
                min={toPixels(value.min)}
                max={toPixels(max)}
                offset={offset}
                toValue={toValue}
                onMove={(max: number) => setValue({ ...value, max })}
                onChange={(max: number) => onValueChange({ ...value, max })}
            />
        </div>
    );
}

interface SliderThumbProps {
    className: string;
    defaultValue: number;
    min: number;
    max: number;
    offset: number;
    toValue: (v: number) => number;
    onMove: (v: number) => void;
    onChange: (v: number) => void;
}

function useSliderThumb(props: SliderThumbProps) {
    const { defaultValue, min, max, offset, onChange, onMove, toValue } = props;
    const [mouseIsDown, setMouseIsDown] = useState<boolean>(false);
    const [shift, setShift] = useState<number>(0);
    const [left, setLeft] = useState<number>(defaultValue);

    const onMouseUp = useCallback(() => {
        if (!mouseIsDown) {
            return;
        }

        setMouseIsDown(false);
        onChange(toValue(left));
    }, [left, mouseIsDown, onChange, toValue]);

    const updateLeft = useCallback(
        (e: MouseEvent) => {
            if (!mouseIsDown) {
                return;
            }

            const left = e.pageX - offset - shift;

            if (left < min) {
                setLeft(min);

                return;
            }

            if (left > max) {
                setLeft(max);

                return;
            }

            setLeft(left);
            onMove(toValue(left));
        },
        [mouseIsDown, shift, min, max, offset, onMove, toValue],
    );

    useEffect(() => {
        window.addEventListener('mouseup', onMouseUp);

        return () => window.removeEventListener('mouseup', onMouseUp);
    }, [onMouseUp]);

    useEffect(() => {
        window.addEventListener('mousemove', updateLeft);

        return () => window.removeEventListener('mousemove', updateLeft);
    }, [mouseIsDown, shift, updateLeft]);

    return { setMouseIsDown, setShift, left };
}

function SliderThumb(props: SliderThumbProps) {
    const { className, toValue } = props;
    const { setMouseIsDown, setShift, left } = useSliderThumb(props);

    return (
        <button
            className={classNames('slider__thumb', className)}
            style={{ left }}
            onMouseDown={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                const node = e.target as HTMLButtonElement;

                setMouseIsDown(true);
                setShift(e.clientX - node.getBoundingClientRect().left);
            }}
        >
            <div className="slider__thumb-value">{toValue(left)}</div>
        </button>
    );
}

function useSlider(el: React.RefObject<HTMLDivElement>) {
    const [width, setWidth] = useState<number | undefined>(undefined);
    const [offset, setOffset] = useState<number>(0);

    const updateWidth = useCallback(() => {
        if (el.current) {
            setWidth(el.current.offsetWidth);
            setOffset(el.current.getBoundingClientRect().left);
        }
    }, [el]);

    useEffect(() => {
        updateWidth();
    }, [el, updateWidth]);

    useEffect(() => {
        window.addEventListener('resize', updateWidth);

        return () => window.removeEventListener('resize', updateWidth);
    }, [updateWidth]);

    return { width, offset };
}

export function Slider(props: Props) {
    const el = useRef<HTMLDivElement>(null);

    const { width, offset } = useSlider(el);

    return <div ref={el}>{width ? <SliderContent {...props} width={width} offset={offset} /> : null}</div>;
}
