import React from 'react';

import './styles.scss';
import { RatingKindValue } from 'shared/src/types/safe-cities';

export interface RatingSwitcherProps {
    value: RatingKindValue;
    onChange: (kind: RatingKindValue) => void;
}

export function RatingSwitcher({ value, onChange }: RatingSwitcherProps) {
    return (
        <div className="app-ratingswitcher">
            <div
                onClick={() => onChange('appRating')}
                className={
                    value === 'appRating'
                        ? 'app-ratingswitcher__option-left _active'
                        : ' app-ratingswitcher__option-left'
                }
            >
                <span className="material-icons">pie_chart</span>
                Rating from stats
            </div>
            <div
                onClick={() => onChange('userRating')}
                className={
                    value === 'userRating'
                        ? 'app-ratingswitcher__option-right _active'
                        : ' app-ratingswitcher__option-right'
                }
            >
                <span className="material-icons">people_alt</span>
                User-driven rating
            </div>
        </div>
    );
}
