import _ from 'lodash';
import React from 'react';

import s from './Modal.module.scss';

export interface ModalProps {
    title: string | React.ReactElement;
    onClose: () => void;
    children: React.ReactNode;
}

export function Modal(props: ModalProps) {
    const { title, onClose, children } = props;

    return (
        <div className={s.modal}>
            <div className={s.container}>
                <div className={s.header}>
                    <div className={s.title}>{title}</div>
                    <div onClick={onClose} className={s.close} />
                </div>
                {children}
            </div>
        </div>
    );
}
