import { service } from 'aidbox-react/lib/services/service';

import { Covid19, Covid19History } from 'shared/src/types/covid-19';

export async function fetchCovid19() {
    return service<Covid19[]>({
        url: `/api/covid_19`,
        method: 'GET',
    });
}

export async function fetchCovid19History(fips: string) {
    return service<Covid19History>({
        url: `/api/covid_19/${fips}/history`,
        method: 'GET',
    });
}
