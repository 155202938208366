import classNames from 'classnames';
import React, { useState } from 'react';

import { useService } from 'aidbox-react/lib/hooks/service';
import { isSuccess } from 'aidbox-react/lib/libs/remoteData';
import { service, sequenceMap } from 'aidbox-react/lib/services/service';

import { addToFavoriteCity, removeFromFavoriteCity } from 'src/services/favoriteCities';
import { authData } from 'src/services/signin';

import { MaterialIcon } from '../HistoryIcon';
import { RenderRemoteData } from '../RenderRemoteData';
import s from './AddToFavoriteButton.module.scss';

interface cityId {
    cityId: number;
}

export function AddToFavoriteButton(Props: cityId) {
    const { cityId } = Props;
    const [userInfo] = authData.useSharedState();
    const [isСhanging, setIsСhanging] = useState(false);

    const [hasFavoriteRemoteData, manager] = useService(
        () =>
            service<boolean>({
                url: `/api/userinfo/${cityId}/favorite`,
            }),
        [cityId],
    );
    const remoteData = sequenceMap({ userInfo, hasFavorite: hasFavoriteRemoteData });

    return (
        <RenderRemoteData
            remoteData={remoteData}
            renderFailure={FavoriteError}
            renderLoading={FavoriteLoading}
            renderNotAsked={FavoriteError}
        >
            {({ hasFavorite }) => {
                return (
                    <div
                        className={classNames(s.favoriteButton, { [s._disable]: isСhanging })}
                        onClick={async () => {
                            if (cityId) {
                                setIsСhanging(true);
                                if (hasFavorite) {
                                    const res = await removeFromFavoriteCity(cityId);
                                    if (isSuccess(res)) {
                                        manager.set(false);
                                    }
                                } else {
                                    const res = await addToFavoriteCity(cityId);
                                    if (isSuccess(res)) {
                                        manager.set(true);
                                    }
                                }
                                setIsСhanging(false);
                            }
                        }}
                    >
                        {hasFavorite ? (
                            <MaterialIcon iconText="favorite" />
                        ) : (
                            <MaterialIcon iconText="favorite_border" />
                        )}
                    </div>
                );
            }}
        </RenderRemoteData>
    );
}
function FavoriteError() {
    return (
        <div className={classNames(s.favoriteButton, s._nonactive)} title="Please Login">
            <MaterialIcon iconText="favorite_border" />
        </div>
    );
}
function FavoriteLoading() {
    return (
        <div className={classNames(s.favoriteButton, s._disabled)}>
            <MaterialIcon iconText="favorite_border" />
        </div>
    );
}
