import _ from 'lodash';
import React from 'react';

import { Covid19HistoryRecord, Covid19History } from 'shared/src/types/covid-19';

import { Chart, ChartData } from './Chart';
import s from './Covid19Widget.module.scss';

interface Props {
    covid19History: Covid19History;
}

function prepareChartData(history: Covid19HistoryRecord[]): ChartData[] {
    return _.map(_.take(history, history.length - 1), ({ date, cases }, index) => ({
        date,
        cases: cases - history[index + 1].cases,
    }));
}

function useCovid19Widget(props: Props) {
    const { covid19History } = props;
    const { history } = covid19History;
    const todayCases = history[0];
    const yesterdayCases = history[1];
    const periodCases = history[history.length - 1];

    const chartData = prepareChartData(history);

    return {
        chartData,
        totalCases: {
            cases: todayCases.cases,
            deaths: todayCases.deaths,
        },
        periodCases: {
            cases: todayCases.cases - periodCases.cases,
            deaths: todayCases.deaths - periodCases.deaths,
        },
        casesSinceYesterday: {
            cases: todayCases.cases - yesterdayCases.cases,
            deaths: todayCases.deaths - yesterdayCases.deaths,
        },
    };
}

export function Covid19Widget(props: Props) {
    const { chartData, totalCases, periodCases, casesSinceYesterday } = useCovid19Widget(props);

    return (
        <div className={s.container}>
            <div className={s.section}>
                <div className={s.sectionTitle}>Last 14 days cases</div>
                <div className={s.sectionValue}>{`+${periodCases.cases}`}</div>
                <Chart width={92} height={12} data={chartData} />
            </div>
            <div className={s.section}>
                <div className={s.sectionTitle}>Total cases</div>
                <div className={s.sectionValue}>{totalCases.cases}</div>
                <div>{`+${casesSinceYesterday.cases}, yesterday`}</div>
            </div>
            <div className={s.section}>
                <div className={s.sectionTitle}>Deaths</div>
                <div className={s.sectionValue}>{totalCases.deaths}</div>
                <div>{`${casesSinceYesterday.deaths}, yesterday`}</div>
            </div>
        </div>
    );
}
