import { service } from 'aidbox-react/lib/services/service';

import { FilterCitiesByStatisticsParams, FilterCitiesByWeatherParams } from 'shared/src/types/safe-cities';

export async function setUserPreferences(
    filter: (FilterCitiesByWeatherParams & FilterCitiesByStatisticsParams) | undefined,
    userProp: 'user_filters' | 'cityId',
) {
    const data = {
        filter: filter,
        userProp: userProp,
    };

    return service({ url: '/api/setUserPreferences', method: 'POST', data: data });
}
export async function getUserPreferences() {
    return service({ url: '/api/userPreferences', method: 'GET' });
}
