export const rangeColors = {
    'very-good': {
        color: '#43A86B',
        dark: '#398F5B',
    },
    good: {
        color: '#FFD466',
        dark: '#E6BF5C',
    },
    average: {
        color: '#FF9966',
        dark: '#E68A5C',
    },
    bad: {
        color: '#FF6666',
        dark: '#E65C5C',
    },
    'very-bad': {
        color: '#612866',
        dark: '#491E4D',
    },
};
