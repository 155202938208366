import React from 'react';

import { useService } from 'aidbox-react/lib/hooks/service';
import { failure, success, isSuccess } from 'aidbox-react/lib/libs/remoteData';

import config from 'shared/src/config';
import { USCity } from 'shared/src/types/cities';

import { WidgetBlock } from 'src/components/WidgetBlock';

import s from './WeatherWidget.module.scss';

interface Position {
    lat: number;
    lon: number;
}

interface Props {
    city: USCity;
}

interface WeatherIcon {
    icon: string;
}

interface CurrentWeatherData {
    dt: number;
    sunrise: number;
    sunset: number;
    temp: number;
    wind_speed: number;
    pressure: number;
    humidity: number;
    weather: WeatherIcon[];
}

interface DailyWeatherData {
    dt: number;
    sunrise: number;
    sunset: number;
    temp: {
        day: number;
        night: number;
    };
    weather: WeatherIcon[];
}

export interface WeatherData {
    current: CurrentWeatherData;
    daily: DailyWeatherData[];
}

const fromKtoF = (t: number) => Math.floor(((t - 273.15) * 9) / 5) + 32;

const weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export function useWeather(city: USCity) {
    const position: Position = {
        lon: city.geometry.y,
        lat: city.geometry.x,
    };
    return useService<WeatherData>(
        async () =>
            fetch(config.baseURL + `/api/weather/current/position?lon=${position.lon}&lat=${position.lat}`)
                .then((response) => response.json())
                .then(({ current, daily }: WeatherData) =>
                    success({
                        current,
                        daily: daily.slice(0, 7),
                    }),
                )
                .catch((err) => failure(err.toString())),
        [city],
    );
}

interface WeatherIconProps {
    size: number;
    weatherIcons: WeatherIcon[];
}

function WeatherIcon(props: WeatherIconProps) {
    return (
        <>
            {props.weatherIcons.map((weather, key) => (
                <img
                    alt=""
                    key={key}
                    width={props.size}
                    className={s.weatherIcon}
                    src={`https://openweathermap.org/img/wn/${weather.icon}@2x.png`}
                />
            ))}
        </>
    );
}

const nextDayNumber = new Date().getDay() + 1;

export function WeatherWidget(props: Props) {
    const [weather] = useWeather(props.city);

    if (!isSuccess(weather)) {
        return null;
    }
    if (!weather.data) {
        return null;
    }

    const {
        data: { current, daily },
    } = weather;

    return (
        <WidgetBlock title="Weather forecast" className={s.container}>
            <div className={s.currentTemp}>
                <WeatherIcon size={100} weatherIcons={current.weather} /> {fromKtoF(current.temp)}° F
            </div>

            <div className={s.currentParams}>
                <div className={s.currentParam}>Precipitation: {current.pressure} hPa</div>
                <div className={s.currentParam}>Humidity: {current.humidity}%</div>
                <div className={s.currentParam}>Wind: {current.wind_speed} mph</div>
            </div>

            <div className={s.dailyWeather}>
                {daily.map((weather: DailyWeatherData, key) => (
                    <div className={s.dayWeather} key={key}>
                        <div className={s.dayWeatherWeakday}>{weekday[(nextDayNumber + key) % weekday.length]}</div>

                        <div className={s.dayWeatherIcon}>
                            <WeatherIcon size={45} weatherIcons={weather.weather} />
                        </div>

                        <div className={s.dayWeatherTemp}>
                            <div className={s.dayWeatherTempDay}>{Math.round(fromKtoF(weather.temp.day))}°</div>
                            <div className={s.dayWeatherTempNight}>{Math.round(fromKtoF(weather.temp.night))}°</div>
                        </div>
                    </div>
                ))}
            </div>
        </WidgetBlock>
    );
}
