import classNames from 'classnames';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga';

import { useService } from 'aidbox-react/lib/hooks/service';
import {
    isFailure,
    isLoading,
    isNotAsked,
    isSuccess,
    loading,
    notAsked,
    RemoteData,
} from 'aidbox-react/lib/libs/remoteData';

import { USCity, USCounty } from 'shared/src/types/cities';
import { Business, BusinessDefaultSearchParams, BusinessSearchParams, mainCategories } from 'shared/src/types/yelp';

import { AddToFavoriteButton } from 'src/components/AddToFavoriteButton';
import { BusinessesDataWidget } from 'src/components/BusinessesDataWidget';
import { CovidDataWidget } from 'src/components/CovidDataWidget';
import { CrimesDataWidget } from 'src/components/CrimesDataWidget';
import { MobilityDataWidget } from 'src/components/MobilityDataWidget';
import { RatingWidget } from 'src/components/RatingWidget';
import ReviewsButton from 'src/components/ReviewsButton';
import { SkySkanerWidget } from 'src/components/SkyScannerWidget';
import { Spinner } from 'src/components/Spinner';
import { WeatherDataWidget } from 'src/components/WeatherDataWidget';
import { YelpLogo } from 'src/components/YelpLogo';
import { CountyInfo } from 'src/containers/CountyInfo';
import { fetchCityByCounty } from 'src/services/cities';
import { searchBusinesses } from 'src/services/yelp';

import { RenderRemoteData } from '../../../components/RenderRemoteData';
import { BusinessListFilter } from '../BusinessFilters';
import { BusinessModal } from '../BusinessModal';
import s from './BusinessList.module.scss';

function useBusinessList(props: Props) {
    const { county } = props;

    const [businessesResponse, setBusinessesResponse] = useState<RemoteData>(notAsked);
    const [cityResponse] = useService(async () => fetchCityByCounty(county.fips), [county]);
    const [placeName, setPlaceName] = useState<string | undefined>(undefined);

    const [filter, setFilter] = useState<BusinessDefaultSearchParams>({
        categories: _.map(mainCategories, (c) => c.alias),
    });

    const [currentBusiness, setCurrentBusiness] = useState<string | undefined>();
    const [showCountyDetails, setShowCountyDetails] = useState<boolean>(false);
    const [currentDataWidget, setCurrentDataWidget] = useState<string | undefined>();

    const loadBusinessData = useCallback(async (params: BusinessSearchParams) => {
        setBusinessesResponse(loading);

        const response = await searchBusinesses(params);

        setBusinessesResponse(response);
    }, []);

    useEffect(() => {
        (async () => {
            if (!placeName) {
                return;
            }

            await loadBusinessData({
                location: placeName,
                ...filter,
            });
        })();
    }, [filter, placeName, loadBusinessData]);

    useEffect(() => {
        (async () => {
            if (isSuccess(cityResponse)) {
                const city = cityResponse.data;
                const cityName = `${city.name}, ${city.state.abbr}`;

                setPlaceName(cityName);
            }

            if (isFailure(cityResponse)) {
                setPlaceName(county.name);
            }
        })();
    }, [cityResponse, county]);

    useEffect(() => {
        setCurrentBusiness(undefined);
    }, [county]);

    return {
        county,
        cityResponse,
        placeName,
        currentBusiness,
        setCurrentBusiness: (v: string | undefined) => {
            if (v) {
                setShowCountyDetails(false);
            }

            setCurrentBusiness(v);
        },
        businessesResponse,
        showCountyDetails,
        setShowCountyDetails: (v: boolean) => {
            if (v) {
                setCurrentBusiness(undefined);
            }

            setShowCountyDetails(v);
        },
        filter,
        setFilter,
        currentDataWidget,
        setShowWidgetData: (v: string | undefined) => {
            setCurrentDataWidget(v);
        },
    };
}

interface BusinessListHeaderProps {
    title?: string;
    onClose: () => void;
    onReview: () => void;
    cityRespose: RemoteData<USCity>;
}

function BusinessListHeader({ title = '', onClose, onReview, cityRespose }: BusinessListHeaderProps) {
    return (
        <div className={s.header}>
            <div className={s.headerTitle}>
                <button className={s.headerBackButton} onClick={onClose} />
                {title}
            </div>
            <RenderRemoteData remoteData={cityRespose}>
                {(city) => {
                    return (
                        <>
                            <ReviewsButton onClick={onReview} cityId={city.id} />
                            <AddToFavoriteButton cityId={city.id} />
                        </>
                    );
                }}
            </RenderRemoteData>
        </div>
    );
}

interface BusinessListBodyProps {
    county: USCounty;
    cityResponse: RemoteData<USCity>;
    businessesResponse: RemoteData<any, any>;
    currentBusiness?: string;
    setCurrentBusiness: (v?: string) => void;
    showCountyDetails: boolean;
    setShowCountyDetails: (v: boolean) => void;
    currentDataWidget: string | undefined;
}

function BusinessListBody(props: BusinessListBodyProps) {
    const {
        county,
        cityResponse,
        businessesResponse,
        currentBusiness,
        setCurrentBusiness,
        showCountyDetails,
        setShowCountyDetails,
        currentDataWidget,
    } = props;

    if (
        isNotAsked(businessesResponse) ||
        isLoading(businessesResponse) ||
        isNotAsked(cityResponse) ||
        isLoading(cityResponse)
    ) {
        return <Spinner />;
    }

    if (isSuccess(businessesResponse) && isSuccess(cityResponse)) {
        const { businesses, total } = businessesResponse.data;

        return (
            <>
                <div className={s.businessResults}>{total === 1 ? '1 Search result' : `${total} Search results`}</div>
                <div className={s.businessList}>
                    {_.map(businesses, (business, index: number) => (
                        <BusinessCard
                            key={`card-${business.id}`}
                            index={index}
                            business={business}
                            onBusinessClick={setCurrentBusiness}
                        />
                    ))}
                </div>
                {currentBusiness ? (
                    <BusinessModal id={currentBusiness} onClose={() => setCurrentBusiness(undefined)} />
                ) : null}
                {showCountyDetails && currentDataWidget ? (
                    <CountyInfo
                        county={county}
                        city={cityResponse.data}
                        onClose={() => setShowCountyDetails(false)}
                        currentDataWidget={currentDataWidget}
                    />
                ) : null}
            </>
        );
    }

    return null;
}

interface Props {
    county: USCounty;
    onClose: () => void;
    openReview: (city: USCity) => void;
}

export function BusinessList(props: Props) {
    const { onClose, openReview } = props;

    const {
        county,
        cityResponse,
        placeName,
        currentBusiness,
        setCurrentBusiness,
        businessesResponse,
        showCountyDetails,
        setShowCountyDetails,
        filter,
        setFilter,
        currentDataWidget,
        setShowWidgetData,
    } = useBusinessList(props);

    return (
        <>
            <BusinessListHeader
                title={placeName}
                onReview={() => {
                    if (isSuccess(cityResponse)) {
                        ReactGA.event({
                            category: 'review',
                            action: 'open review',
                            label: `${cityResponse.data.name}`,
                        });
                        openReview(cityResponse.data);
                    }
                }}
                onClose={onClose}
                cityRespose={cityResponse}
            />
            <div className={s.content}>
                <RenderRemoteData remoteData={cityResponse}>
                    {(city) => (
                        <div className={s.cityDetailsWidget}>
                            <WeatherDataWidget
                                city={city}
                                currentDataWidget={currentDataWidget}
                                onDataWidget={setShowWidgetData}
                                onDetails={(v) => {
                                    setShowCountyDetails(v);
                                }}
                            />
                            <CovidDataWidget
                                fips={city.county.fips}
                                currentDataWidget={currentDataWidget}
                                onDataWidget={setShowWidgetData}
                                onDetails={(v) => {
                                    setShowCountyDetails(v);
                                }}
                            />
                            <BusinessesDataWidget
                                id={city.id}
                                currentDataWidget={currentDataWidget}
                                onDataWidget={setShowWidgetData}
                                onDetails={(v) => {
                                    setShowCountyDetails(v);
                                }}
                            />
                            <MobilityDataWidget
                                county={city.county.name}
                                currentDataWidget={currentDataWidget}
                                onDataWidget={setShowWidgetData}
                                onDetails={(v) => {
                                    setShowCountyDetails(v);
                                }}
                            />
                            <CrimesDataWidget
                                cityId={city.id}
                                currentDataWidget={currentDataWidget}
                                onDataWidget={setShowWidgetData}
                                onDetails={(v) => {
                                    setShowCountyDetails(v);
                                }}
                            />
                        </div>
                    )}
                </RenderRemoteData>
                {isSuccess(cityResponse) ? <SkySkanerWidget cityData={cityResponse.data} /> : <>Loading...</>}
                <BusinessListFilter filter={filter} setFilter={setFilter} />
                <BusinessListBody
                    county={county}
                    cityResponse={cityResponse}
                    businessesResponse={businessesResponse}
                    currentBusiness={currentBusiness}
                    setCurrentBusiness={setCurrentBusiness}
                    setShowCountyDetails={setShowCountyDetails}
                    showCountyDetails={showCountyDetails}
                    currentDataWidget={currentDataWidget}
                />
            </div>
        </>
    );
}

interface BusinessCardProps {
    index: number;
    business: Business;
    onBusinessClick: (id: string) => void;
}

export function BusinessCard(props: BusinessCardProps) {
    const { index, business, onBusinessClick } = props;
    const { id, name, image_url, location, price, categories: businessCategories, rating, review_count } = business;
    const address = _.join(
        _.compact([location.address1, location.address2, location.address3, location.city, location.state]),
        ', ',
    );
    const categories = _.join(_.compact(_.map(businessCategories, 'title')), ', ');

    return (
        <div className={s.card} onClick={() => onBusinessClick(id)}>
            <div className={s.cardInfo}>
                <div className={s.cardName}>{`${index + 1}. ${name}`}</div>
                <div className={classNames(s.cardRecord, s.businessRating)}>
                    <RatingWidget rating={rating} className={s.cardRating} />
                    <YelpLogo />
                </div>
                <div className={classNames(s.cardRecord, s.businessReviews)}>Based on {review_count} Reviews</div>
                <div className={s.cardRecord}>{_.join(_.compact([price, categories]), ' • ')}</div>
                <div className={s.cardRecord}>{address}</div>
            </div>
            <div className={s.cardImage}>{image_url ? <img src={image_url} alt="" /> : null}</div>
        </div>
    );
}
