import classNames from 'classnames';
import React, { useCallback, useState } from 'react';

import { MaterialIcon } from '../HistoryIcon';
import s from './RatingSelect.module.scss';

export interface RatingSelectProps {
    label: string;
    options: string[];
    tooltip: string;
    onChange: (option: string) => void;
}

function getOption(rating: number | null, options: string[]) {
    let option = null;
    let starStaticClass = null;

    switch (rating) {
        case 1:
        case 2:
            option = options[2];
            starStaticClass = 'ratingSelectStarBad';
            break;
        case 3:
        case 4:
            option = options[1];
            starStaticClass = 'ratingSelectStarGood';
            break;
        case 5:
            option = options[0];
            starStaticClass = 'ratingSelectStarVeryGood';
            break;
    }
    return { option, starStaticClass };
}

function getHoverOptions(hover: number | null, options: string[]) {
    let hoverOption = null;
    let starHoverClass = null;

    switch (hover) {
        case 1:
        case 2:
            starHoverClass = 'ratingSelectStarBad';
            hoverOption = options[2];
            break;
        case 3:
        case 4:
            starHoverClass = 'ratingSelectStarGood';
            hoverOption = options[1];
            break;
        case 5:
            starHoverClass = 'ratingSelectStarVeryGood';
            hoverOption = options[0];
            break;
        case null:
            starHoverClass = null;
            hoverOption = null;
    }
    return { hoverOption, starHoverClass };
}

function useRating(props: RatingSelectProps) {
    const { options, onChange } = props;
    const [rating, setRating_] = useState<number | null>(null);
    const [hover, setHover] = useState<number | null>(null);

    const { option, starStaticClass } = getOption(rating, options);

    const setRating = useCallback(
        (rating: number) => {
            setRating_(rating);
            const { option } = getOption(rating, options);
            if (option) {
                onChange(option);
            }
        },
        [onChange],
    );

    const { hoverOption, starHoverClass } = getHoverOptions(hover, options);

    return {
        rating,
        setRating,
        option,
        hoverOption,
        hover,
        setHover,
        starHoverClass,
        starStaticClass,
    };
}

export default function RatingSelect(props: RatingSelectProps) {
    const { label, tooltip } = props;
    const { setRating, rating, hover, setHover, starHoverClass, starStaticClass, option, hoverOption } = useRating(
        props,
    );
    const [showTooltp, setShowTooltp] = useState(false);

    return (
        <div className={classNames(s.ratingSelect)}>
            <div
                className={s.ratingInfoWrapper}
                onMouseOver={(event) => {
                    setShowTooltp(true);
                }}
                onMouseLeave={() => setShowTooltp(false)}
            >
                <MaterialIcon iconText="help_outline" className={s.ratingInfo} />
            </div>
            <div className={classNames(s.ratingSelectTitle)}>{label}</div>
            <div className={classNames(s.ratingSelectStarsBlock)}>
                <div className={classNames(s.ratingSelectOption)}>{hoverOption ? hoverOption : option}</div>
                {[...Array(5)].map((circle, i) => {
                    const ratingValue = i + 1;
                    return (
                        <label
                            className={classNames(s.ratingSelectStarWrapper)}
                            onMouseOver={() => {
                                setHover(ratingValue);
                            }}
                            onMouseLeave={() => setHover(null)}
                            key={`satar-${ratingValue}-${label}`}
                        >
                            <input
                                type="radio"
                                name={`rating-${label}`}
                                value={ratingValue}
                                onClick={() => {
                                    setRating(ratingValue);
                                }}
                                className={classNames(s.ratingSelectRadio)}
                            />
                            <MaterialIcon
                                iconText="star"
                                className={
                                    rating
                                        ? classNames(
                                              s.ratingSelectStar,
                                              {
                                                  [s[`${starHoverClass}`]]:
                                                      starHoverClass && ratingValue <= (hover || rating),
                                              },
                                              {
                                                  [s[`${starStaticClass}`]]:
                                                      starStaticClass && !starHoverClass && ratingValue <= rating,
                                              },
                                          )
                                        : hover
                                        ? classNames(s.ratingSelectStar, {
                                              [s[`${starHoverClass}`]]: starHoverClass && ratingValue <= hover,
                                          })
                                        : classNames(s.ratingSelectStar)
                                }
                            ></MaterialIcon>
                        </label>
                    );
                })}
            </div>
            <div className={classNames(s.toolTip, { [s[`toolTip__displayed`]]: showTooltp })}>{tooltip}</div>
        </div>
    );
}
